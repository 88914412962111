import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from '../../../store/StoreProvider';
import { types } from '../../../store/storeReducer';
import {
  bytesToGB,
  formatearGBColeccion,
  formatFileSize,
  obtenerTamanoAproximado,
  redondearSize
} from '../../../utils/functions';

const useColecciones = () => {
  const { movie, dataAEnviar } = useStore();
  const dispatch = useDispatch();
  const [parts, setParts] = useState(movie?.parts || []);
  const [sizeAll, setSizeAll] = useState(0);
  const [totalSize, setTotalSize] = useState('');
  const [bitesAll, setBitesAll] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  const handleInputChange = (index, newValue) => {
    // Crear una copia profunda del estado actual
    const updatedMovie = JSON.parse(JSON.stringify(movie));

    // Asegurarnos que seasons existe
    if (!updatedMovie.parts) {
      updatedMovie.parts = [];
    }

    // Asegurarnos que el objeto en el índice existe
    if (!updatedMovie.parts[index]) {
      updatedMovie.parts[index] = {};
    }

    console.log('updatedMovie', updatedMovie);

    // Actualizar el valor del input específico en el array de parts
    updatedMovie.parts[index].title_fecha = newValue;

    // Actualizar también el estado local de parts
    const updatedParts = [...parts];
    updatedParts[index] = {
      ...updatedParts[index],
      title_fecha: newValue
    };
    setParts(updatedParts);

    // Actualizar el estado utilizando la función setMovie
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        parts_info: updatedMovie.parts
      }
    });
  };

  const handleChangeSize = (index, e) => {
    const files = e.target.files;
    let sizeBites = 0;

    // Obtener tamaños de los archivos actuales
    const currentFileSizes = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const fileSize = files[i].size;
        const fileName = files[i].name.toLowerCase();
        if (fileName.endsWith('.mkv') || fileName.endsWith('.mp4')) {
          sizeBites += fileSize;
          currentFileSizes.push(fileSize);
        }
      }
    }

    setTotalFiles(prev => prev + files?.length);
    setBitesAll(prev => prev + sizeBites);
    const size = formatFileSize(sizeBites);

    // Actualizar el estado con el nuevo archivo
    const updatedMovie = { ...movie };
    const newValue = `${updatedMovie.parts[index].title_fecha} Peso: ${size}`;
    handleInputChange(index, newValue);

    // Mantener un registro de todos los tamaños de archivos individuales
    const allFileSizes = [...(movie.allFileSizes || []), ...currentFileSizes];
    updatedMovie.allFileSizes = allFileSizes;

    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        parts_info: updatedMovie.parts
      }
    });

    // Calcular el tamaño mínimo y máximo de todos los archivos
    const minSize = formatearGBColeccion(
      redondearSize(Math.min(...allFileSizes))
    );
    const maxSize = formatearGBColeccion(
      redondearSize(Math.max(...allFileSizes))
    );
    const totalSizeFormatted = formatFileSize(bitesAll + sizeBites);

    setTotalSize(
      `${minSize} a ${maxSize} Aprox./Película (Total: ${totalSizeFormatted})`
    );
    setSizeAll(parseFloat(bytesToGB(bitesAll + sizeBites)));
  };

  useEffect(() => {
    dispatch({
      type: types.DATAAENVIAR,
      payload: {
        ...dataAEnviar,
        PesoArchivo: totalSize
      }
    });
  }, [totalSize]);

  useEffect(() => {
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        parts_info: parts
      }
    });
  }, [parts]);

  return {
    handleInputChange,
    handleChangeSize,
    setTotalSize,
    parts,
    movie,
    sizeAll,
    totalSize
  };
};

export default useColecciones;
