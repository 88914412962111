import React, { useState } from 'react';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { deletePostWordpress } from '../../utils/apiwp';
import { toast } from 'react-toastify';
import style from './deletePost.module.css';
import { decodeHtmlEntities } from '../../utils/functions';

/**
 * Componente para eliminar un post de WordPress
 * @param {Object} post - Objeto con la información del post
 * @param {Function} onDelete - Función a ejecutar después de eliminar el post
 */
const DeletePost = ({ post, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Función para eliminar el post
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deletePostWordpress(post.id);
      if (response.status === 200) {
        // toast.success('Post eliminado correctamente');
        setShowModal(false);
        if (onDelete) onDelete(post.id);
      } else {
        toast.error('Error al eliminar el post');
      }
    } catch (error) {
      toast.error('Error al eliminar el post');
      console.error('Error al eliminar el post:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.deletePostContainer}>
      <Card className={style.postCard}>
        <Card.Body>
          <Card.Title className={style.postTitle}>
            {decodeHtmlEntities(post.title?.rendered || 'Sin título')}
          </Card.Title>
          <div className={style.postInfo}>
            <p>
              <strong>Uploader:</strong> {post.user?.name || 'Desconocido'}
            </p>
            <p>
              <strong>ID:</strong> {post.id}
            </p>
            <p>
              <strong>Estado:</strong> {post.status}
            </p>
            <p>
              <strong>Fecha:</strong> {new Date(post.date).toLocaleDateString()}
            </p>
            <p>
              <strong>Enlace:</strong>{' '}
              <a href={post.link} target="_blank" rel="noopener noreferrer">
                {post.link}
              </a>
            </p>
          </div>
          <Button
            variant="danger"
            className={style.deleteButton}
            onClick={() => setShowModal(true)}>
            Eliminar Post
          </Button>
        </Card.Body>
      </Card>

      {/* Modal de confirmación */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro que deseas eliminar el post{' '}
          <strong>{post.title?.rendered}</strong>?
          <p className={style.warningText}>Esta acción no se puede deshacer.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDelete} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Eliminando...
              </>
            ) : (
              'Eliminar'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeletePost;
