import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import {
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Tab,
  Tabs
} from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
// import Button from '../button/Button';
import Button from 'react-bootstrap/Button';
import './paste.css';
import Sortable from '../Sortable/Sortable';
import { useDispatch } from '../../store/StoreProvider';
import { crearPaste, updatePastes } from '../../utils/apipaste';
// import toast, { Toaster } from 'react-hot-toast';
import {
  getLocalStorage,
  extractDomain,
  createObjectIconServer,
  createObjectUrlImagesPaste,
  createObjectUrlImagesPasteVip,
  groupByReferer
} from '../../utils/functions';
import { types } from '../../store/storeReducer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import images from '../../assets/images';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button as ButtonM,
  IconButton
} from '@mui/material';
import FormChild from './components/FormChild/FormChild';
import ServersList from './components/ServersList/ServersList';
import { useServerStore } from './store/ServersStroe';
import useFormChild from './hooks/useFormChild';
import {
  bannerColectionButton,
  bannerColectionTop
} from '../../config/constants';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import PasteViejo from './components/PasteViejo/PasteViejo';
import { useSearchCoincidence } from '../SearchCoincidence/store/store';

const isFree = '0';
const isVip = '1';

const validateTypePasteInitialData = {
  serie: '1serie',
  coleccion: '2coleccion'
};
let posicionArray = 1;
const InitialData = {
  _titulo: '',
  _typePaste: '',
  _enlaces: [
    {
      id: 'defaultId',
      enlace: '',
      nombreEnlace: '',
      typeEnlace: ''
    }
  ]
};

const urlImagesPaste = {
  1: 'https://dl.dropbox.com/s/wf9ewehivbt6wtl/GoogleDrive_FREE%20SOLO.png',
  2: 'https://dl.dropbox.com/s/5atd2ce65ztkeki/GoogleDrive_FREE%20SOLO2.png',
  3: 'https://dl.dropbox.com/s/hgtw1lcv8cx5uah/Uptobox%20FREE.png',
  4: 'https://dl.dropbox.com/s/mmu8bp709muwm1o/TeraBox%20FREE.png',
  // 5: 'https://dl.dropbox.com/s/mmu8bp709muwm1o/TeraBox%20FREE.png',
  6: 'https://dl.dropbox.com/s/9j1bedozl8jnvtl/1Fichier%20FREE.png',
  7: 'https://dl.dropbox.com/s/c2opswe8ebi7211/GoogleDrive_VIP.png',
  8: 'https://dl.dropbox.com/s/1q1evrc7d9w6f15/GoogleDrive_VIP2.png',
  9: 'https://dl.dropbox.com/s/qgquq3qrtkx6key/Sync_VIP.png'
};
const urlImagesPasteVip = {
  1: 'https://dl.dropbox.com/s/ijvpgp7h6430zh6/GoogleDrive_FREE.png',
  2: 'https://dl.dropbox.com/s/bwh8xkec2esgrpa/GoogleDrive_FREE2.png',
  3: 'https://dl.dropbox.com/s/4et8ikxc9zve218/Uptobox.png',
  4: 'https://dl.dropbox.com/s/h95bake1afgy7qs/TeraBox.png',
  // 5: 'https://dl.dropbox.com/s/h95bake1afgy7qs/TeraBox.png',
  6: 'https://dl.dropbox.com/s/nnwbzobyd0d5ojh/1Fichier.png',
  7: 'https://dl.dropbox.com/s/c2opswe8ebi7211/GoogleDrive_VIP.png',
  8: 'https://dl.dropbox.com/s/1q1evrc7d9w6f15/GoogleDrive_VIP2.png',
  9: 'https://dl.dropbox.com/s/qgquq3qrtkx6key/Sync_VIP.png'
};
const urlImagesSeries = {
  T: 'https://dl.dropboxusercontent.com/s/b2ll5wd5vum9187/TEMPORADAS.png',
  C: 'https://dl.dropboxusercontent.com/s/a4z4msxpd5jzi5t/Capitulo.png'
};

// Array para dropdown de series y capitulos
const serieListNumero = new Array(50).fill(null).map((u, i) => i + 1);

/**
 * @function input por defecto para agregar un nuevo enlace
 */
const InputCheckDefault = ({
  id,
  enlace,
  handleChangeInput,
  handleInputListDelete,
  renderImg,
  handleAcortadorInputList,
  typeEnlace,
  inputList
}) => {
  const dispatch = useDispatch();

  const handleChangeEnlaceStrored = enlace => {
    dispatch({ type: 'CHANGE_ENLACE_STORED', payload: enlace });
  };

  const [data] = useServerStore();
  const iconServer2 = createObjectIconServer(data);

  // Función para obtener los objetos únicos por nombre
  const getUniqueByName = array => {
    const uniqueNames = new Set();
    const uniqueObjects = array.filter(item => {
      if (!uniqueNames.has(item.name)) {
        uniqueNames.add(item.name);
        return true;
      }
      return false;
    });
    return uniqueObjects;
  };

  const uniqueIconServer2 = getUniqueByName(iconServer2);

  // Función para verificar si la URL ingresada coincide con alguna de las URLs de los objetos con el mismo nombre
  const isUrlMatch = name => {
    return iconServer2.some(
      item => item.name === name && item.value === typeEnlace
    );
  };

  // Función para obtener la imagen correspondiente al nombre
  const getImageForName = name => {
    const item = iconServer2.find(item => item.name === name);
    return item ? item.img : '';
  };

  return (
    <>
      <Row className="mb-3">
        <Col sm={3}>
          <Sortable
            enlace={enlace}
            id={id}
            handleAcortadorInputList={handleAcortadorInputList}
            inputList={inputList}
          />
        </Col>
        <Col sm={4}>
          <InputGroup size="sm">
            <Form.Control
              placeholder="Enlace"
              aria-label="Enlace"
              aria-describedby="basic-addon2"
              name="enlace"
              id={id}
              value={enlace}
              onChange={handleChangeInput}
            />
          </InputGroup>
        </Col>
        <Col sm={4} className="d-flex align-items-center justify-content-start">
          {uniqueIconServer2.map(check => (
            <Form.Check
              inline
              label={renderImg(
                {
                  name: check.name,
                  img: getImageForName(check.name)
                },
                true
              )}
              name={id}
              type="radio"
              value={check.value}
              id={id + check._id}
              className="checkM"
              onChange={handleChangeInput}
              // checked={typeEnlace === check.value}
              checked={isUrlMatch(check.name)}
            />
          ))}
        </Col>
        <Col
          md={1}
          // style={{ background: '#809' }}
          className="d-flex justify-content-end m-0">
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleInputListDelete(id)}>
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <FaTrashAlt />
              Eliminar
            </div>
            {/* Eliminar */}
          </Button>
        </Col>
      </Row>
    </>
  );
};

/**
 *
 * @function input dinamico para agregar un nuevo enlace
 */
const InputCheck = ({
  id,
  enlace,
  handleInputListDelete,
  handleChangeInput,
  handleAcortadorInputList,
  renderImg,
  inputSerie,
  typeEnlace,
  handleInputList,
  inputList
}) => {
  const [data] = useServerStore();
  const iconServer2 = createObjectIconServer(data);
  // Función para obtener los objetos únicos por nombre
  const getUniqueByName = array => {
    const uniqueNames = new Set();
    const uniqueObjects = array.filter(item => {
      if (!uniqueNames.has(item.name)) {
        uniqueNames.add(item.name);
        return true;
      }
      return false;
    });
    return uniqueObjects;
  };

  const uniqueIconServer2 = getUniqueByName(iconServer2);

  // Función para verificar si la URL ingresada coincide con alguna de las URLs de los objetos con el mismo nombre
  const isUrlMatch = name => {
    return iconServer2.some(
      item => item.name === name && item.value === typeEnlace
    );
  };

  // Función para obtener la imagen correspondiente al nombre
  const getImageForName = name => {
    const item = iconServer2.find(item => item.name === name);
    return item ? item.img : '';
  };
  const COMPONENT_DEFAULT = (
    <Row className="mb-3">
      <Col md={3}>
        <Sortable
          enlace={enlace}
          id={id}
          handleAcortadorInputList={handleAcortadorInputList}
          inputList={inputList}
        />
      </Col>
      <Col md={4}>
        <InputGroup size="sm">
          <Form.Control
            placeholder="Enlace"
            aria-label="Enlace"
            aria-describedby="basic-addon2"
            name="enlace"
            value={enlace}
            id={id}
            onChange={handleChangeInput}
          />
        </InputGroup>
      </Col>
      <Col md={4} className="d-flex align-items-center justify-content-start">
        {uniqueIconServer2.map((check, index) => (
          <Form.Check
            key={index}
            inline
            label={renderImg({ name: check.name, img: check.img })}
            name={id}
            type="radio"
            value={check.value}
            id={id + check._id}
            className="checkM"
            onChange={handleChangeInput}
            // checked={typeEnlace === check.value}
            checked={isUrlMatch(check.name)}
          />
        ))}
      </Col>
      <Col
        md={1}
        // style={{ background: '#809' }}
        className="d-flex justify-content-end m-0">
        <Button
          size="sm"
          variant="danger"
          type="button"
          onClick={() => handleInputListDelete(id)}>
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <FaTrashAlt />
            Eliminar
          </div>
          {/* Eliminar */}
        </Button>
      </Col>
    </Row>
  );
  const COMPONENT_SERIE_AND_COLLECTION = {
    '1serie': (
      <Row className="mb-3">
        {/* <Col md={2}></Col> */}
        <Col md={2} className="d-grid gap-2">
          <Button
            size="sm"
            variant="warning"
            type="button"
            onClick={() => handleInputList('referer', enlace)}>
            <div className="d-flex gap-2 align-items-center justify-content-center fw-bold">
              <FaPlus />
              Agregar Enlace
            </div>
          </Button>
        </Col>
        <Col md={5}>
          <InputGroup size="sm">
            <Form.Select
              aria-label="Default select example"
              onChange={handleChangeInput}
              name="enlace"
              defaultValue={enlace}
              id={id}>
              {serieListNumero.map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Col>
        <Col
          md={4}
          className="d-flex align-items-center justify-content-center">
          <Form.Check
            inline
            label={'Temporada'}
            name={id}
            type="radio"
            value="T"
            id={id + 'drive'}
            // id={checked}
            className="checkM"
            onChange={handleChangeInput}
            // defaultChecked={typeEnlace === 'T'}
            checked={typeEnlace === 'T'}
          />
          <Form.Check
            inline
            label={'Capítulo'}
            name={id}
            type="radio"
            value="C"
            id={id + 'mega'}
            // id={checked}
            className="checkM"
            onChange={handleChangeInput}
            // defaultChecked={typeEnlace === 'C'}
            checked={typeEnlace === 'C'}
          />
        </Col>
        <Col
          md={1}
          // style={{ background: '#809' }}
          className="d-flex justify-content-end m-0">
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleInputListDelete(id)}>
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <FaTrashAlt />
              Eliminar
            </div>
            {/* Eliminar */}
          </Button>
        </Col>
        {/* <Row className="mt-2">
                    <Col className="d-grid gap-2">
                        <Button
                            size="sm"
                            variant="warning"
                            type="button"
                            // onClick={() => handleInputList()}
                        >
                            <div className="d-flex gap-2 align-items-center justify-content-center fw-bold">
                                <FaPlus />
                                Agregar Enlace
                            </div>
                        </Button>
                    </Col>
                </Row> */}
      </Row>
    ),
    '2coleccion': (
      <Row className="mb-3">
        <Col md={2}></Col>
        <Col md={5}>
          <InputGroup size="sm">
            <Form.Control
              placeholder="Nombre"
              aria-label="Enlace"
              aria-describedby="basic-addon2"
              name="enlace"
              value={enlace}
              id={id}
              onChange={handleChangeInput}
            />
          </InputGroup>
        </Col>
        <Col
          md={5}
          // style={{ background: '#809' }}
          className="d-flex justify-content-end m-0">
          <Button
            size="sm"
            variant="danger"
            type="button"
            onClick={() => handleInputListDelete(id)}>
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <FaTrashAlt />
              Eliminar
            </div>
            {/* Eliminar */}
          </Button>
        </Col>
      </Row>
    )
  };
  return COMPONENT_SERIE_AND_COLLECTION[inputSerie] || COMPONENT_DEFAULT;
};

const Paste = ({
  initialdata: newData,
  keyTabDefault = 'misenlaces',
  inputNameProp = null,
  handleEnlacesPastes = () => {}
  // urlImagesPaste = {},
  // urlImagesPasteVip = {}
}) => {
  const { searchCoincidence } = useSearchCoincidence();
  const userData = getLocalStorage('user');
  const inicial = newData ? newData : InitialData;
  const titleinitial = inputNameProp ? inputNameProp : inicial._titulo;
  const isFree = inputNameProp ? '1' : inicial._typePaste;
  const [inputList, setInputList] = useState(inicial._enlaces);
  const [groupedDataSerie, setGroupedDataSerie] = useState([inputList]);
  const [vipOrFree, setVipOrFree] = useState(isFree.toString());
  const [inputName, setInputName] = useState(titleinitial);
  const [loadingPaste, setLoadingPaste] = useState(false);
  const [inputSerie, setInputSerie] = useState(
    validateTypePasteInitialData[inicial?.type_paste] || '0'
  );
  const [inputTextArea, setInputTextArea] = useState(null);
  const [displayValue, setDisplayValue] = useState(inputName);
  const [data] = useServerStore();
  const iconServer2 = createObjectIconServer(data);
  const urlImagesPaste = createObjectUrlImagesPaste(data);
  const urlImagesPasteVip = createObjectUrlImagesPasteVip(data);
  useFormChild();

  const dispatch = useDispatch();

  /*----------------------------------*/

  const handleReset = () => {
    setInputList([
      {
        id: 'defaultId',
        enlace: '',
        nombreEnlace: '',
        typeEnlace: ''
      }
    ]);
    setVipOrFree('0');
    setInputName('');
    setInputSerie('0');
    setDisplayValue('');
    setInputTextArea('');
  };

  const handleInputList = (typeSerie = null, enlace) => {
    console.log('typeSerie', typeSerie);
    if (typeSerie === '1serie') {
      setInputList([
        ...inputList,
        {
          id: nanoid(),
          type: typeSerie,
          enlace: posicionArray
        }
      ]);
      posicionArray = posicionArray + 1;
    } else if (typeSerie === '2coleccion') {
      setInputList([
        ...inputList,
        {
          id: nanoid(),
          type: typeSerie
        }
      ]);
    } else if (typeSerie === 'referer') {
      setInputList([
        ...inputList,
        {
          id: nanoid(),
          referer: enlace
        }
      ]);
    } else {
      setInputList([...inputList, { id: nanoid() }]);
    }
  };
  const handleInputListDelete = id => {
    setInputList(inputList.filter(item => item.id !== id));
  };

  const generarObjForEnlacesAutomaticos = enlace => {
    let hostName = extractDomain(enlace);

    if (hostName === 'teraboxapp') {
      hostName = 'terabox';
    }
    let sendValueObj;
    const objServidor = iconServer2.filter(
      s => extractDomain(s.img2) === hostName
    )[0];
    sendValueObj = objServidor ? { typeEnlace: objServidor?.value } : {};
    return sendValueObj;
  };

  const handleChangeInput = (e, valueId = false) => {
    const validateCheckId = (id, id2) => {
      return !!(
        id2 === id + 'drive' ||
        id2 === id + 'mega' ||
        id2 === id + 'uptobox' ||
        id2 === id + 'terabox' ||
        // id2 === id + 'teraboxapp' ||
        id2 === id + '1fichier' ||
        id2 === id + 'gdv1' ||
        id2 === id + 'gdv2' ||
        id2 === id + 'ln5'
      );
    };

    setInputList(
      inputList.map(item => {
        if (item.id === e.target.id || validateCheckId(item.id, e.target.id)) {
          // Validar que tip si es un checkbox o un campo input
          const typeEnlace = validateCheckId(item.id, e.target.id)
            ? 'typeEnlace'
            : e.target.name;

          const sendObj = generarObjForEnlacesAutomaticos(e.target.value);

          return {
            ...item,
            ...sendObj,
            [typeEnlace]: e.target.value
          };
        }

        return { ...item };
      })
    );
  };

  const renderImg = ({ name = '', img }, mostrar = false) => (
    <div className="wrapperImg">
      <img src={img} />
      {/* {mostrar && name} */}
    </div>
  );

  const handleChangeInputName = e => {
    setInputName(e.target.value);
    // const newSentence = e.target.value.replace(/\bFREE\b$/i, '');
    const newSentence = e.target.value.replace(/\bFREE\b$/i, '');
    const textFreeOrVip =
      vipOrFree === '0' ? 'FREE' : vipOrFree === '1' ? 'VIP' : '';
    setDisplayValue(newSentence + textFreeOrVip);
  };
  const handlePlantilla = () => {
    const groupedData = groupByReferer(inputList);
    const typePost =
      vipOrFree.toString() === '0'
        ? '[url=https://t.me/viphdlatino][img]https://vip.hdlatino.us/wp-content/uploads/2023/05/20230530191836328.gif[/img][/url][B][color=red]¿BENEFICIOS DE USUARIO VIP?[/color]🥇  📽[color=black]VER VIDEO[/color][/B] ⏬ ⏬ ⏬ ⏬ ⏬[youtube]https://www.youtube.com/watch?v=psHDPgLejTk[/youtube]\r\n\r\n\r\n\r\n\r\n'
        : '[url=https://t.me/viphdlatino/?s=CHRISHD][img]https://i.ibb.co/MPLD69d/Enlaces-Rotos.png[/img][/url]';

    const banner =
      vipOrFree.toString() === '0'
        ? '[url=https://vip.hdlatino.us/%e2%9a%9c-membresia-vip-%f0%9f%8f%86/][img]https://dl.dropbox.com/s/64u3j570cuyj0pg/BannerFREE.png[/img][/url]\r\n\r\n\r\n'
        : '[url=https://tawk.to/Pedidos][img]https://dl.dropbox.com/s/96sgsdlvdjfjp82/Banner.png[/img][/url]\r\n[color=black]​​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​▫️​​▫️​▫️▫️​▫️​▫️​▫️​▫️​​▫️​▫️​​▫️​​▫️​▫️​▫️​▫️​▫️​▫️​​​​​​​​[/color]\r\n\r\n\r\n';

    const plantilla = `
[center]
${banner}

${inputList.map(
  item =>
    `[url=${item.enlace}][img]${
      vipOrFree.toString() === '0'
        ? urlImagesPaste[item.typeEnlace]
        : urlImagesPasteVip[item.typeEnlace]
    }[/img][/url]\r\n\r\n\r\n`
)} 
${typePost}


[/center]`;

    // Plantilla para agregar temporadas y capitulos
    const plantillaSerie = `
[center]
${banner}

${Object.keys(groupedData)?.map(referer =>
  groupedData[referer]?.map(item => {
    return item?.type === '1serie'
      ? `[color=red]------------------------------------------------------------------------------------------------------------------------------------------------------[/color][img]${
          urlImagesSeries[item?.typeEnlace]
        }[/img] [b][color=#b50000][size=80px]${
          item?.enlace
        }[/size] [/color][/b]\r\n`
      : item.enlace &&
          `[url=${item.enlace}][img]${
            vipOrFree.toString() === '0'
              ? urlImagesPaste[item.typeEnlace]
              : urlImagesPasteVip[item.typeEnlace]
          }[/img][/url]\r\n\r\n\r\n`;
  })
)} 


${typePost}
[/center]`;

    // Plantilla para agregar temporadas y capitulos
    const plantillaColeccion = `
[center]
${bannerColectionTop}

${inputList.map(item => {
  return item?.type === '2coleccion'
    ? `[b][color=red]--------------------------------------------------------------------------------------------------------------------------[/color][/b]
        
        [img]https://i.ibb.co/DgCPtzH/icons8-done.gif[/img]  [size=20px][b][color=black]${item?.enlace}[/color] [/b][/size][img]https://i.ibb.co/DRRYm71/icons8-download-from-the-cloud-1.gif[/img]
        
        
        `
    : item.enlace &&
        `[img]https://i.ibb.co/Z8CnBCh/icons8-advance.gif[/img][url=${
          item.enlace
        }][img]${
          vipOrFree.toString() === '0'
            ? urlImagesPaste[item.typeEnlace]
            : urlImagesPasteVip[item.typeEnlace]
        }[/img][/url]
              
              
              `;
})} 
${bannerColectionButton}
[/center]`.replaceAll(',[', '[');

    const OBJ_PLANTILLA = {
      '1serie': plantillaSerie,
      '2coleccion': plantillaColeccion
    };

    // return inputSerie === '1' ? plantillaSerie : plantilla;
    // setInputTextArea(inputSerie === '1serie' ? plantillaSerie : plantilla);
    setInputTextArea(OBJ_PLANTILLA[inputSerie] || plantilla);
  };

  /**
   * ZONA DE ACORTADOR
   */
  const handleAcortadorInputList = (linkAcortado, id) => {
    const newEnlacesAcortados = inputList.filter(item => {
      return item.id === id ? (item.enlace = linkAcortado) : id;
    });
    setInputList(newEnlacesAcortados);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const funSubmit = newData ? updatePastes : crearPaste;

    if (inputSerie === '1serie' && inputList[0].id === 'defaultId') {
      inputList.shift();
    }

    const validateTypePaste = {
      '1serie': 'serie',
      '2coleccion': 'coleccion'
    };

    const objAEnviar = {
      pass: '',
      Titulo: inputName,
      Mirror1: inputTextArea,
      Mname1: vipOrFree === '0' ? 'SERVIDORES (FREE)' : 'SERVIDORES (VIP)',
      Mesrep: null,
      user_id: null,
      vip: vipOrFree === '0' ? '0' : '1',
      enlaces_json: JSON.stringify(inputList),
      type_paste: validateTypePaste[inputSerie] || 'pelicula'
    };

    setLoadingPaste(true);
    const data = await funSubmit(objAEnviar, newData?.pasteID);
    setLoadingPaste(false);

    if (data.status === 'error') {
      // toast.error('Error al actualizar el paste');
      toast.error('Error al actualizar el paste!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      return;
    }
    if (data.error) {
      // toast.error('Error al crear el paste');
      toast.error('Error al crear el paste', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      return;
    }
    // toast.success('Paste creado con éxito!');
    toast.success('Paste creado con éxito!', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light'
    });
    inputNameProp && handleEnlacesPastes(vipOrFree);

    dispatch({
      type: types.MULTIPASTE_CREADO,
      payload: data
    });

    return data;
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    handlePlantilla();
  }, [inputList, vipOrFree]);

  useEffect(() => {
    const groupedData = groupByReferer(inputList);
    setGroupedDataSerie(() => groupedData);
  }, [inputList]);

  const isFreeOrVip = () => {
    return vipOrFree.toString() === '0' ? 'FREE' : 'VIP';
  };

  useEffect(() => {
    const newSentence =
      vipOrFree.toString() === '0'
        ? inputName.replace(/\bVIP\b$/i, '')
        : inputName.replace(/\bFREE\b$/i, '');
    if (vipOrFree) {
      const textFreeOrVip = isFreeOrVip();
      const textF = `${newSentence.trim()} ${textFreeOrVip}`;
      setInputName(textF.trim());
    }
  }, [vipOrFree]);

  // Carga el nombre por primeva vez y remplaza el tipo de post (FREE, VIP)
  useEffect(() => {
    let newSentence;
    newSentence =
      vipOrFree.toString() === '0'
        ? inputName.replace(/\bFREE\b$/i, '')
        : inputName.replace(/\bVIP\b$/i, '');
    const textFreeOrVip = isFreeOrVip();

    if (newData || inputNameProp)
      setInputName(newSentence.trim() + ' ' + textFreeOrVip);
  }, []);

  useEffect(() => {
    console.log('inicial._enlaces', inicial._enlaces);
    setInputList(inicial._enlaces);
  }, [inputSerie]);

  useEffect(() => {
    handlePlantilla();
  }, [urlImagesPasteVip]);

  return (
    <>
      <Form id="paste" onSubmit={handleSubmit}>
        <Row>
          {/* style="border: 1px solid #dee2e6; border-top: 0;" */}
          <Col className="p-3 d-flex justify-content-between">
            {newData ? (
              <div className="d-flex justify-content-start gap-2 align-items-center fw-bold">
                <div>
                  <img
                    src={images.agregar_paste}
                    alt=""
                    width="45"
                    height="45"
                  />
                </div>
                <div>
                  <h2>EDITAR PASTE</h2>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-start gap-2 align-items-center fw-bold">
                <div>
                  <img
                    src={images.agregar_paste}
                    alt=""
                    width="45"
                    height="45"
                  />
                </div>
                <div>
                  <h2>AGREGAR PASTE</h2>
                </div>
              </div>
            )}

            <div>
              <Col className="d-flex gap-3">
                {/* <Button
                                    onClick={() =>
                                        openInNewTab(
                                            userData?.id_paste === 0
                                                ? 'https://vip.hdlatino.us/pastes/logminpanel.php?action=showall'
                                                : 'https://vip.hdlatino.us/pastes/userpanel.php?action=showall'
                                        )
                                    }>
                                    Mis pastes
                                </Button> */}

                {newData ? (
                  <Button>
                    <Link
                      style={{
                        textDecoration: 'none',
                        color: 'var(--color-main)'
                      }}
                      to="/mis-pastes">
                      Mis pastes
                    </Link>
                  </Button>
                ) : (
                  <IconButton aria-label="delete" onClick={handleReset}>
                    <RestartAltIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Col>
            </div>
          </Col>
          <Row>
            <Col sm={6}>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Nombre del paste"
                  aria-label="Nombre del paste"
                  aria-describedby="basic-addon1"
                  size="sm"
                  name={inputName}
                  value={inputName}
                  onChange={e => setInputName(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text id="basic-addon1">Tipo</InputGroup.Text>
                <Form.Select
                  aria-label="Default select example"
                  onChange={e => setVipOrFree(() => e.target.value)}>
                  <option disabled selected value>
                    -- select an option --
                  </option>
                  <option
                    value="0"
                    selected={vipOrFree === '0'}
                    className="option_hover">
                    Publico
                  </option>
                  <option value="1" selected={vipOrFree === '1'}>
                    VIP
                  </option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col sm={3}>
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Text id="basic-addon1">Formato</InputGroup.Text>
                <Form.Select
                  aria-label="Default select example"
                  onChange={e => setInputSerie(e.target.value)}
                  value={inputSerie}>
                  <option value="0" className="option_hover">
                    Película
                  </option>
                  <option value="1serie">Serie</option>
                  <option value="2coleccion">Colección</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey={keyTabDefault}
                id="justify-tab-example"
                size="sm">
                <Tab
                  eventKey="misenlaces"
                  title="Agregar Enlaces"
                  className="p-3"
                  style={{
                    border: '1px solid #dee2e6',
                    borderTop: 0
                  }}>
                  {/* {!newData &&
                                        inputSerie !== '1serie' &&
                                        inputSerie !== '2coleccion' && (
                                            <InputCheckDefault
                                                renderImg={renderImg}
                                                id={inputList[0].id}
                                                handleChangeInput={
                                                    handleChangeInput
                                                }
                                                enlace={inputList[0].enlace}
                                                handleAcortadorInputList={
                                                    handleAcortadorInputList
                                                }
                                                typeEnlace={
                                                    inputList[0].typeEnlace
                                                }
                                                inputList={inputList}
                                            />
                                        )} */}

                  {inputSerie === '1serie' &&
                    Object.keys(groupedDataSerie).map(
                      referer =>
                        referer && (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header">
                              Temporada {referer}
                            </AccordionSummary>
                            <AccordionDetails>
                              {groupedDataSerie[referer].map(
                                (item, index) =>
                                  item.id !== 'defaultId' && (
                                    <InputCheck
                                      key={index}
                                      id={item.id}
                                      handleInputListDelete={
                                        handleInputListDelete
                                      }
                                      handleChangeInput={handleChangeInput}
                                      enlace={item.enlace}
                                      handleAcortadorInputList={
                                        handleAcortadorInputList
                                      }
                                      renderImg={renderImg}
                                      inputSerie={item.type}
                                      typeEnlace={item.typeEnlace}
                                      handleInputList={handleInputList}
                                      inputList={inputList}
                                    />
                                  )
                              )}
                            </AccordionDetails>
                          </Accordion>
                        )
                    )}
                  {inputSerie === '0' &&
                    inputList.map((item, index) =>
                      item.id !== 'defaultId' ? (
                        <InputCheck
                          key={index}
                          id={item.id}
                          handleInputListDelete={handleInputListDelete}
                          handleChangeInput={handleChangeInput}
                          enlace={item.enlace}
                          handleAcortadorInputList={handleAcortadorInputList}
                          renderImg={renderImg}
                          inputSerie={item.type}
                          typeEnlace={item.typeEnlace}
                          handleInputList={handleInputList}
                          inputList={inputList}
                        />
                      ) : (
                        <InputCheckDefault
                          renderImg={renderImg}
                          id={inputList[0].id}
                          handleChangeInput={handleChangeInput}
                          enlace={inputList[0].enlace}
                          handleAcortadorInputList={handleAcortadorInputList}
                          handleInputListDelete={handleInputListDelete}
                          typeEnlace={inputList[0].typeEnlace}
                          inputList={inputList}
                        />
                      )
                    )}

                  {inputSerie === '2coleccion' &&
                    inputList.map(
                      (item, index) =>
                        item.id !== 'defaultId' && (
                          <InputCheck
                            key={index}
                            id={item.id}
                            handleInputListDelete={handleInputListDelete}
                            handleChangeInput={handleChangeInput}
                            enlace={item.enlace}
                            handleAcortadorInputList={handleAcortadorInputList}
                            renderImg={renderImg}
                            inputSerie={item.type}
                            typeEnlace={item.typeEnlace}
                            handleInputList={handleInputList}
                            inputList={inputList}
                          />
                        )
                    )}
                  {/* <InputCheck renderImg={renderImg} /> */}

                  {(inputSerie === '0' || inputSerie === '2coleccion') && (
                    <Row>
                      <Col className="d-grid gap-2">
                        <Button
                          size="sm"
                          variant="warning"
                          type="button"
                          onClick={() => handleInputList()}>
                          <div className="d-flex gap-2 align-items-center justify-content-center fw-bold">
                            <FaPlus />
                            Agregar Enlace
                          </div>
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {inputSerie === '1serie' && (
                    <Row className="mt-2">
                      <Col className="d-grid gap-2">
                        <Button
                          size="sm"
                          variant="warning"
                          type="button"
                          onClick={() => handleInputList('1serie')}>
                          <div className="d-flex gap-2 align-items-center justify-content-center fw-bold">
                            <FaPlus />
                            Agregar Temporada/Capitulo
                          </div>
                        </Button>
                      </Col>
                    </Row>
                  )}

                  {inputSerie === '2coleccion' && (
                    <Row className="mt-2">
                      <Col className="d-grid gap-2">
                        <Button
                          size="sm"
                          variant="warning"
                          type="button"
                          onClick={() => handleInputList('2coleccion')}>
                          <div className="d-flex gap-2 align-items-center justify-content-center fw-bold">
                            <FaPlus />
                            Agregar Película
                          </div>
                        </Button>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <Form.Group
                        className="mt-3"
                        controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          as="textarea"
                          rows={10}
                          value={
                            inputList[0]?.enlace || inputList[1]?.enlace
                              ? inputTextArea
                              : ''
                          }
                          onChange={e => setInputTextArea(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    form="paste"
                    onClick={e => handleSubmit(e)}
                    // type="submit"
                    className="d-flex align-items-center justify-content-center gap-2 w-100">
                    {loadingPaste && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {newData ? 'Actualizar' : 'Enviar'}
                  </Button>
                </Tab>
                {userData.roles === 'admin' ? (
                  <Tab
                    // onClick={() => alert('hola2')}
                    eventKey="config"
                    title="Configuración"
                    className="p-3"
                    style={{
                      border: '1px solid #dee2e6',
                      borderTop: 0
                    }}>
                    <FormChild />
                    <ServersList />
                  </Tab>
                ) : null}
                {searchCoincidence?.content?.rendered ? (
                  <Tab
                    eventKey="paste-viejo"
                    title="Paste Viejo"
                    className="p-3"
                    style={{
                      border: '1px solid #dee2e6',
                      borderTop: 0
                    }}>
                    <PasteViejo />
                  </Tab>
                ) : null}
              </Tabs>
            </Col>
          </Row>
        </Row>
      </Form>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
    </>
  );
};

export default Paste;
