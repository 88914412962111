import React, { useEffect, useState } from 'react';
import { useSearchCoincidence } from '../../../SearchCoincidence/store/store';
import { extraerEnlacesDinamicos } from '../../../../utils/functions';
import { getPasteForUrl } from '../../../../utils/apipaste';
import { Spinner } from 'react-bootstrap';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Container,
  Divider,
  Chip,
  Paper,
  Link,
  IconButton,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import MovieIcon from '@mui/icons-material/Movie';
import LinkIcon from '@mui/icons-material/Link';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const PasteViejo = () => {
  const { searchCoincidence } = useSearchCoincidence();
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [movieInfo, setMovieInfo] = useState(null);
  const [links, setLinks] = useState([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copiedLink, setCopiedLink] = useState('');

  const handlePaste = async () => {
    setLoading(true);
    if (searchCoincidence?.content?.rendered) {
      const enlaces = extraerEnlacesDinamicos(
        searchCoincidence?.content?.rendered
      );
      console.log('enlaces', enlaces);
      const url = enlaces[1];
      const urlObj = new URL(url);
      // Usar URLSearchParams para obtener el valor del parámetro "v"
      const query = urlObj?.searchParams?.get('v');
      const data = await getPasteForUrl(query);
      setLoading(false);
      setData(data);
    }
  };

  useEffect(() => {
    handlePaste();
  }, [searchCoincidence]);

  useEffect(() => {
    if (data && data.status === 'success' && data.data.paste.length > 0) {
      const pasteData = data.data.paste[0];
      setMovieInfo(pasteData);

      // Procesar los enlaces desde Mirror1
      const mirror1Content = pasteData.Mirror1;
      if (mirror1Content) {
        // Extraer URLs de Mirror1
        const extractedLinks = extractLinksFromMirror(mirror1Content);
        setLinks(extractedLinks);
      }
    }
  }, [data]);

  // Función para extraer enlaces e imágenes de Mirror1
  const extractLinksFromMirror = mirrorContent => {
    const extractedLinks = [];

    // Expresión regular para encontrar URLs dentro de etiquetas [url]
    const urlRegex = /\[url=([^\]]+)\]/g;
    let match;

    while ((match = urlRegex.exec(mirrorContent)) !== null) {
      const url = match[1];

      // Filtrar enlaces de Tawk y Telegram
      if (url.includes('tawk.to') || url.includes('t.me')) {
        continue;
      }

      // Buscamos si hay una imagen asociada con este enlace
      const imgStartPos = mirrorContent.indexOf('[img]', match.index);
      const imgEndPos = mirrorContent.indexOf('[/img]', imgStartPos);

      let imageUrl = null;
      if (
        imgStartPos > -1 &&
        imgEndPos > -1 &&
        imgStartPos < mirrorContent.indexOf('[/url]', match.index)
      ) {
        imageUrl = mirrorContent.substring(imgStartPos + 5, imgEndPos);
      }

      // Crear un nombre descriptivo para el enlace
      let name = '';
      if (url.includes('onedrive') || url.includes('sharepoint')) {
        name = 'OneDrive';
      } else if (url.includes('drive.google')) {
        name = 'Google Drive';
      } else {
        // Extraer dominio para el nombre
        try {
          const domain = new URL(url).hostname.replace('www.', '');
          name =
            domain.split('.')[0].charAt(0).toUpperCase() +
            domain.split('.')[0].slice(1);
        } catch {
          name = 'Enlace ' + (extractedLinks.length + 1);
        }
      }

      extractedLinks.push({
        url,
        imageUrl,
        name
      });
    }

    return extractedLinks;
  };

  // Función para copiar enlace al portapapeles
  const handleCopyLink = (url, name) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopiedLink(name);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
      })
      .catch(err => {
        console.error('Error al copiar el enlace: ', err);
      });
  };

  // Cerrar la notificación
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopySuccess(false);
  };

  if (!movieInfo) {
    return <Typography variant="body1">Cargando información...</Typography>;
  }

  // Extraer título y tipo de la película
  const { Titulo, type_paste, vip } = movieInfo;

  if (loading) return <Spinner />;
  return (
    <Container>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 2,
          border: '1px solid #eaeaea',
          bgcolor: '#fafafa'
        }}>
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h5"
            component="h1"
            sx={{
              fontWeight: 500,
              mb: 1,
              color: '#333'
            }}>
            {Titulo}
            <Tooltip title="Copiar titulo">
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleCopyLink(Titulo, Titulo)}
                sx={{
                  border: '1px solid #e0e0e0',
                  margin: '0 0 0 10px'
                }}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <MovieIcon sx={{ mr: 1, color: '#666' }} fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              {type_paste &&
                type_paste.charAt(0).toUpperCase() + type_paste.slice(1)}
            </Typography>
            {vip === 1 && (
              <Chip
                label="VIP"
                size="small"
                color="primary"
                sx={{ ml: 2, height: '20px' }}
              />
            )}
          </Box>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Typography
          variant="subtitle1"
          sx={{ mb: 2, fontWeight: 500, color: '#333' }}>
          Enlaces de descarga
        </Typography>

        <Grid container spacing={2}>
          {links.map((link, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  border: '1px solid #eaeaea',
                  borderRadius: 1,
                  '&:hover': {
                    boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
                  }
                }}>
                <Grid container alignItems="center" spacing={2}>
                  {/* Imagen o ícono */}
                  <Grid item>
                    {link.imageUrl ? (
                      <img
                        src={link.imageUrl}
                        alt={link.name}
                        style={{
                          maxWidth: '100%',
                          height: '36px',
                          objectFit: 'contain'
                        }}
                      />
                    ) : (
                      <CloudDownloadIcon color="primary" />
                    )}
                  </Grid>

                  {/* Nombre y URL */}
                  <Grid item xs>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {link.name}
                    </Typography>
                    <Link
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: '0.75rem',
                        color: '#666',
                        display: 'block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}>
                      {link.url}
                    </Link>
                  </Grid>

                  {/* Botones alineados a la izquierda */}
                  <Grid item container justifyContent="flex-start" spacing={1}>
                    <Grid item>
                      <Tooltip title="Copiar enlace">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => handleCopyLink(link.url, link.name)}
                          sx={{
                            border: '1px solid #e0e0e0'
                          }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          borderRadius: 6,
                          textTransform: 'none',
                          px: 2
                        }}>
                        Descargar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {links.length === 0 && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: 'center', my: 3 }}>
            No se encontraron enlaces disponibles
          </Typography>
        )}
      </Paper>

      {/* Notificación de copia exitosa */}
      <Snackbar
        open={copySuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}>
          Enlace de {copiedLink} copiado al portapapeles
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PasteViejo;
