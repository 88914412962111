import axios from 'axios';
import { Buffer } from 'buffer';
import { REACT_APP_API_REST_WP, REACT_APP_API_REST_WP_JWT } from '../env';
import { decompressFromUTF16 } from 'lz-string';
import metadata from '../metadata';
import moment from 'moment';
import { toast } from 'react-toastify';
import { HORA_FINALIZAR } from '../constants';

// const userData = JSON.parse(localStorage.getItem('user'));
const userData = getLocalStorage('user');

export const definirTrailer = result => {
  const trailer = result?.videos?.results.filter(item => {
    if (item.type === 'Trailer') {
      return (item.url = `https://www.youtube.com/watch?v=${item.key}`);
    }
  });
  return trailer;
};

export const handleRuntime = result => {
  if (result?.runtime && result?.runtime > 0) {
    return `${result?.runtime} min`;
  } else if (result?.episode_run_time && result?.episode_run_time > 0) {
    return `${result?.episode_run_time} min`;
  } else {
    return null;
  }
};

export const handleDirectors = (result, typeMovie = true) => {
  let directors = [];
  result?.credits?.crew.forEach(function (entry) {
    if (entry.job === 'Director') {
      directors.push(entry.name);
    }
  });

  return directors.length > 0 ? directors : null;
};

// export const getCategoriesWordpress = async type => {
//     const url = REACT_APP_API_REST_WP;
//     try {
//         const { data } = await axios.get(`${url}/${type}?per_page=99`);
//         return data;
//     } catch (error) {
//         return error;
//     }
// };
export const getCategoriesWordpress = async type => {
  const url = REACT_APP_API_REST_WP;
  try {
    const response = await axios.get(`${url}/${type}`);
    const totalTags = response.headers['x-wp-total'];
    let allTags = [];
    let totalPages = Math.ceil(totalTags / 99);
    for (let i = 1; i <= totalPages; i++) {
      const tagsResponse = await axios.get(
        `${url}/${type}?per_page=99&page=${i}`
      );
      // allTags = allTags.concat(tagsResponse.data);
      allTags = [...allTags, ...tagsResponse.data];
    }
    return allTags;
  } catch (error) {
    console.error(error);
  }
};

export const createCategoriesAndTasgs = async (values, type) => {
  const url = REACT_APP_API_REST_WP;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${userData?.keywp}`
  };
  return await axios.post(`${url}/${type}`, values, {
    headers
  });
};

// verificar si el usuario existe en la db de wordpress
export const LoginWP = async (user, password) => {
  const url = REACT_APP_API_REST_WP;

  try {
    return await axios.get(`${url}/posts?status=draft`, {
      headers: {
        Authorization:
          'Basic ' + Buffer.from(`${user}:${password}`).toString('base64')
      }
    });
  } catch (error) {
    return error;
  }
};

export async function getUserInfo(username, password) {
  // try {
  // const username = 'LIVE79';
  // const password = 'Live.792023';

  return await axios.post(REACT_APP_API_REST_WP_JWT, {
    username: username,
    password: password
  });
}

export const verificarRol = async apiwp => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${apiwp}`
  };

  const data = await axios.get(
    `${REACT_APP_API_REST_WP}/users?roles=administrator`,
    {
      headers
    }
  );

  return data;
};

// console.log(createPost('TElWRTc5OkxpdmUuNzkyMDIz'));

// buscar data del usuarui en la db de wordpress
export const searchDataUser = async () => {
  try {
    return await axios.get(`${REACT_APP_API_REST_WP}/users`);
  } catch (error) {
    return error;
  }
};

export const createTags = async values => {
  const url = REACT_APP_API_REST_WP;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${userData?.keywp}`
  };
  try {
    const { data } = await axios.post(`${url}/tags`, values, {
      headers
    });
    return data;
  } catch (error) {
    return error;
  }
};

export function filterByQuality(quality, data, nameCalidad) {
  // crea todas las validaciones posibles para data, para que no se rompa el codigo
  if (!data) return [];

  var filtered = data?.filter(function (item) {
    return item?.name === nameCalidad;
  });
  return filtered[0]?.data.filter(function (item) {
    return item?.label.toUpperCase() === quality.toUpperCase();
  });
}
export function filterByQualityClean(quality, data, nameCalidad) {
  var filtered = data?.filter(function (item) {
    return item?.name === nameCalidad;
  });
  return filtered[0]?.data.filter(function (item) {
    return item?.label.toUpperCase() !== quality.toUpperCase();
  });
}
export const findString = (word, string) => {
  return string.indexOf(word) > -1;
};

export const validateAudioExiste = (data, word) => {
  return data.filter(function (item) {
    return item.label.toUpperCase().includes(word.toUpperCase());
  });
};

// FORMATEAR HTML
export function formatHtml(html) {
  var tab = '\t';
  var result = '';
  var indent = '';

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += indent + '<' + element + '>\r\n';

    if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
}

// OBTENER IMDB DE LA PLANTILLA HTML
export const extraerImdbPlantilla = html => {
  if (!html) return;
  const expresion = /https:\/\/www\.imdb\.com\/title\/(.*) t/g;
  const urlImdb = html?.match(expresion);
  if (!urlImdb) return null;
  const urltext = urlImdb[0]?.slice(0, -3);
  const IMDB = new URL(urltext)?.pathname?.split('/')[2];
  return IMDB;
};

function decimalAdjust(type, value, exp) {
  // Si la exp es indefinida o cero ...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // Si el valor no es un número o la exp no es un entero ...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  // Desplazar hacia atrás
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

// Techo decimal
if (!Math.ceil10) {
  Math.ceil10 = function (value, exp) {
    return decimalAdjust('ceil', value, exp);
  };
}
export const ceil10 = (num1, num2) => Math.ceil10(num1, num2);

export const arrayVacio = arr => !Array.isArray(arr) || arr.length === 0;

// Obtener datos de localStorage
export function getLocalStorage(key) {
  const data = JSON.parse(
    decompressFromUTF16(window.localStorage.getItem(key) || 'ᯢ粠 ')
  );

  if (typeof data === 'object') {
    return Object.entries(data).length !== 0 ? data : false;
  }
  return data;

  // console.log('datata', data);
  // return data || null;
}

// Extraer todas las urls de un texto
export function extraerUrlsDeTextos(texto) {
  if (texto) {
    const exp =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

    const arrayEnlaces = texto.match(exp);

    return arrayEnlaces;
  }
}

//Extraer enlace vip
export function extraerEnlaceVip(texto) {
  if (texto) {
    const exp = /link5vip(.*)\" target/g;
    const textvip1 = texto?.match(exp)[0];
    const enlaceVip = extraerUrlsDeTextos(textvip1);

    return enlaceVip.length > 0 ? enlaceVip[0] : false;
  }
}
//Extraer enlace vip
export function extraerEnlaceFree(texto) {
  if (texto) {
    const exp = /link5free(.*)\" target/g;
    const textvip1 = texto.match(exp)[0];
    const enlaceVip = extraerUrlsDeTextos(textvip1);

    return enlaceVip.length > 0 ? enlaceVip[0] : false;
  }
}

function validarYAgregarHTTPS(url) {
  // Verificar si la URL comienza con "https://"
  if (
    !url.startsWith('https://') &&
    !url.startsWith('http://') &&
    url !== '' &&
    url !== null
  ) {
    // Si no comienza con "https://", agregarlo al inicio
    url = 'https://' + url;
  }
  return url;
}
export function obtenerNombresDeHost(urls) {
  const nombresDeHost = [];

  if (!urls || urls?.length === 0) return nombresDeHost;

  urls.forEach(url => {
    const urlObj = new URL(validarYAgregarHTTPS(url));
    let nombreDeHost;

    // Caso específico para "https://drive.google.com"
    if (urlObj.host === 'drive.google.com') {
      nombreDeHost = 'GDrive';
    } else {
      // Para otras URLs, toma la primera parte del nombre de host
      nombreDeHost = urlObj.host.split('.')[0];
    }

    // Capitaliza la primera letra del nombre de host
    nombreDeHost = nombreDeHost.charAt(0).toUpperCase() + nombreDeHost.slice(1);
    nombresDeHost.push(nombreDeHost);
  });

  return nombresDeHost;
}

// DECODIFICAR CARACTERES ESPECIALES DE HTML (DECODIFICAR ENTIDADES HTML)
export function decodeEntity(inputStr) {
  var textarea = document.createElement('textarea');
  textarea.innerHTML = inputStr;
  return textarea.value;
}

export function parsearFecha(fecha) {
  if (fecha) {
    const fechaFinalString = fecha; // la fecha final como un string
    const partes = fechaFinalString.split('-'); // dividir la fecha en día, mes y año

    const dia = parseInt(partes[0], 10); // parsear el día a un número
    const mes = parseInt(partes[1], 10); // parsear el mes a un número
    const anio = parseInt(partes[2], 10); // parsear el año a un número

    // crear una nueva fecha usando los valores parseados
    const fechaFinal = new Date(anio, mes - 1, dia);

    const hoy = new Date(); // hoy

    const milisegundosPorDia = 86400000;

    const milisegundosRestantes = fechaFinal.getTime() - hoy.getTime();
    const diasRestantes = Math.ceil(milisegundosRestantes / milisegundosPorDia);

    return diasRestantes;
  }
}
export function validarFechaVencida(fecha) {
  if (fecha) {
    const fechaFinal = new Date(fecha); // la fecha final
    const hoy = new Date(); // hoy

    const milisegundosPorDia = 86400000;

    const milisegundosRestantes = fechaFinal.getTime() - hoy.getTime();
    const diasRestantes = Math.ceil(milisegundosRestantes / milisegundosPorDia);

    // console.log('diasRestantes', diasRestantes);

    if (diasRestantes < 5) {
      return true;
    } else {
      return false;
    }
  }
}

function capitalizarPrimeraLetra(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function crearArrayDeObjetos(obj) {
  const acortadores = crearArrayInicialAcortadores(obj?.acortadores).map(
    acor => ({ name: acor.name, api: acor.host })
  );
  if (obj) {
    delete obj.createdAt;
    delete obj.updatedAt;
    delete obj.keywp;
    delete obj.acortadores;
    delete obj.password;
    delete obj.id;
    delete obj.ouo;
    delete obj.shrinkme;
    delete obj.exe;
    delete obj.cpmlink;
    delete obj.fc;
    delete obj.pass;
  }

  let extractedProperties = [];
  for (let prop in obj) {
    extractedProperties.push({
      name: capitalizarPrimeraLetra(prop),
      api: obj[prop]
    });
  }
  return [...extractedProperties, ...acortadores];
}

export function crearArrayInicialAcortadores(objArray) {
  let extractedProperties = [];
  if (objArray) {
    for (let i = 0; i < objArray?.length; i++) {
      extractedProperties.push({
        id: objArray[i]?.id,
        name: capitalizarPrimeraLetra(objArray[i]?.acor_nombre),
        key: objArray[i]?.acor_nombre,
        host: objArray[i]?.acor_token
      });
    }
  }

  return extractedProperties;
}

export function getNameValues(array) {
  return array.reduce((acc, obj) => {
    acc[obj.acor_nombre] = '';
    return acc;
  }, {});
}

//Extraer dominio sin extensión de una url
export function extractDomain(url = false) {
  if (url) {
    // Modificamos la regex para capturar el dominio principal y los subdominios
    const regex =
      /^(?:https?:\/\/)?(?:[a-zA-Z0-9-]+\.)*([a-zA-Z0-9-]+)\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?.*$/;
    const match = url.match(regex);
    if (match && match.length > 1) {
      return match[1];
    }
  }
  return null;
}

export function clearProvidersRepetidos(providers) {
  if (providers) {
    for (let i = 0; i < providers?.length; i++) {
      for (let j = i + 1; j < providers?.length; j++) {
        if (providers[i]?.short_name === providers[j]?.short_name) {
          providers?.splice(j, 1);
          j--;
        }
      }
    }
  }
  return providers;
}

export function extractRentAndBuy(obj) {
  const result = {};
  for (const country in obj) {
    if (obj.hasOwnProperty(country)) {
      const countryData = obj[country];
      if (
        countryData.hasOwnProperty('rent') &&
        countryData.hasOwnProperty('buy')
      ) {
        result[country] = {
          rent: countryData.rent,
          buy: countryData.buy
        };
      }
    }
  }
  return result;
}
export function extractNotRentAndBuy(obj) {
  const result = {};
  for (const country in obj) {
    if (obj.hasOwnProperty(country)) {
      const countryData = obj[country];
      if (
        !countryData.hasOwnProperty('rent') &&
        !countryData.hasOwnProperty('buy')
      ) {
        result[country] = countryData;
      }
    }
  }
  return result;
}
export function extractRent(obj) {
  const result = {};
  for (const country in obj) {
    if (obj.hasOwnProperty(country)) {
      const countryData = obj[country];
      if (countryData.hasOwnProperty('rent')) {
        result[country] = countryData;
      }
    }
  }
  return result;
}
export function extractBuy(obj) {
  const result = {};
  for (const country in obj) {
    if (obj.hasOwnProperty(country)) {
      const countryData = obj[country];
      if (countryData.hasOwnProperty('buy')) {
        result[country] = countryData;
      }
    }
  }
  return result;
}
export function checkTag(array, tagAVerificar) {
  for (let i = 0; i < array?.length; i++) {
    if (array[i]?.name === tagAVerificar) {
      return true;
    }
  }
  return false;
}

// EXTRAER NOMBRE DE LA URL PARA MANEJAR LAS CLIDADES
export function getFileNameFromUrl(url) {
  const regex = /\/([^/]+)\.[^./]+$/;
  const match = url.match(regex);
  if (match && match.length > 1) {
    return match[1];
  }
  return null;
}

// PINTAR URL SEGUN CALIDAD O RESOLUCION
export function paintUrl(value, nameObj, data) {
  const item = data
    .find(a => a.name === nameObj)
    .data.find(d => d.value === value);
  if (item) {
    return item.url;
  }
}

// AGREGAR OBJETO A LA DATA DE CALIDAD, RESOLUCION ETC
export function addMetadata(value, nameObj, data, addOrDelete) {
  addOrDelete === 'add'
    ? data.find(a => a.name === nameObj).data.push(value)
    : data.find(a => a.name === nameObj).data.pop(value);
  if (data) {
    return data;
  }
}

export function changeCalidadSinExtra(nameObj, metadata, remplazar = false) {
  const data = [
    {
      label: '60FPS',
      value: '60FPS',
      url: 'https://i.ibb.co/5GK6fnT/60FPS.png'
    },
    {
      label: 'BD25',
      value: 'BD25',
      url: 'https://i.ibb.co/1XP8ZH4/BD25.png'
    },
    {
      label: 'BD50',
      value: 'BD50',
      url: 'https://i.ibb.co/nr2Ckw9/BD50.png'
    },
    {
      label: 'BDRip',
      value: 'BDRip',
      url: 'https://i.ibb.co/JC4tbxP/BDRIP.png'
    },
    {
      label: 'BRRip',
      value: 'BRRip',
      url: 'https://i.ibb.co/GcS90xQ/BRRIP.png'
    },
    {
      label: 'DVDRip',
      value: 'DVDRip',
      url: 'https://i.ibb.co/MRqYDcb/DVDRIP.png'
    },
    // {
    //     "label": "HDRip",
    //     "value": "HDRip",
    //     "url": "https://i.ibb.co/F07ZHxL/HDRIP.png"
    // },
    {
      label: 'HDTV',
      value: 'HDTV',
      url: 'https://i.ibb.co/0jk9T9H/HDTV.png'
    },
    {
      label: 'PLACEBO',
      value: 'PLACEBO',
      url: 'https://i.ibb.co/MnhV6nW/PLACEBO.png'
    },
    {
      label: 'REMUX',
      value: 'REMUX',
      url: 'https://i.ibb.co/0Gm7XRB/REMUX.png'
    },
    {
      label: 'BDREMUX',
      value: 'BDREMUX',
      url: 'https://i.ibb.co/fkQXdFj/BDREMUX.png'
    },
    {
      label: 'WEBDL',
      value: 'WEBDL',
      url: 'https://i.ibb.co/njkQpqH/WEBDL.png'
    },
    {
      label: 'WEBRip',
      value: 'WEBRip',
      url: 'https://i.ibb.co/GWyJtM4/WEBRIP.png'
    },
    {
      label: 'X265',
      value: 'X265',
      url: 'https://i.ibb.co/vQ27YgT/X265.png'
    },
    // {
    //     label: 'CAMRIP',
    //     value: 'CAMRIP',
    //     url: 'https://i.ibb.co/R4vWSqR/CAMRIP.png'
    // },
    // {
    //     label: 'ANIME',
    //     value: 'ANIME',
    //     url: 'https://i.ibb.co/1Jpcqj6/ANIME.png'
    // },

    {
      label: 'HDTS',
      value: 'HDTS',
      url: 'https://i.ibb.co/1d2RzrM/HDTS.png'
    }
    // {
    //     label: 'WWE',
    //     value: 'WWE',
    //     url: 'https://i.ibb.co/4NS36Lk/WWE.png'
    // },
    // {
    //     label: 'SERIES',
    //     value: 'SERIES',
    //     url: 'https://i.ibb.co/tQv8rjP/SERIES.png'
    // }
  ];

  const data2 = [
    {
      label: '60FPS',
      value: '60FPS',
      url: 'https://i.ibb.co/YbcC4yh/60FPS.png'
    },
    {
      label: 'BD25',
      value: 'BD25',
      url: 'https://i.ibb.co/qgGcpd8/BD25.png'
    },
    {
      label: 'BD50',
      value: 'BD50',
      url: 'https://i.ibb.co/zxmd34W/BD50.png'
    },
    {
      label: 'BDRip',
      value: 'BDRip',
      url: 'https://i.ibb.co/Y0699HN/BDRIP.png'
    },
    {
      label: 'BRRip',
      value: 'BRRip',
      url: 'https://i.ibb.co/D84ch31/BRRIP.png'
    },
    {
      label: 'DVDRip',
      value: 'DVDRip',
      url: 'https://i.ibb.co/pL4tsqq/DVDRIP.png'
    },
    // {
    //   label: 'HDRip',
    //   value: 'HDRip',
    //   url: 'https://i.ibb.co/F07ZHxL/HDRIP.png'
    // },
    {
      label: 'HDTV',
      value: 'HDTV',
      url: 'https://i.ibb.co/SfJBTBw/HDTV.png'
    },
    {
      label: 'PLACEBO',
      value: 'PLACEBO',
      url: 'https://i.ibb.co/Bjkv6tH/PLACEBO.png'
    },
    {
      label: 'REMUX',
      value: 'REMUX',
      url: 'https://i.ibb.co/YLHKxj9/REMUX.png'
    },
    {
      label: 'BDREMUX',
      value: 'BDREMUX',
      url: 'https://i.ibb.co/bdyGp76/BDREMUX.png'
    },
    {
      label: 'WEBDL',
      value: 'WEBDL',
      url: 'https://i.ibb.co/sRXxQMJ/WEBDL.png'
    },
    {
      label: 'WEBRip',
      value: 'WEBRip',
      url: 'https://i.ibb.co/C0F2X4K/WEBRIP.png'
    },
    {
      label: 'X265',
      value: 'X265',
      url: 'https://i.ibb.co/6tFv2z3/X265.png'
    },
    // {
    //     label: 'CAMRIP',
    //     value: 'CAMRIP',
    //     url: 'https://i.ibb.co/Nr19QJM/CAMRIP.png'
    // },
    {
      label: 'HDTS',
      value: 'HDTS',
      url: 'https://i.ibb.co/bB0JRjb/HDTSS.png'
    }
    // {
    //   label: 'ANIME',
    //   value: 'ANIME',
    //   url: 'https://i.ibb.co/L17nRxw/ANIME.png'
    // }
  ];

  remplazar
    ? (metadata.find(a => a.name === nameObj).data = data2)
    : (metadata.find(a => a.name === nameObj).data = data);
  if (nameObj) {
    return metadata;
  }
}

export function validateUrl(url) {
  const validDomains = [
    'https://i.ibb.co',
    'https://i.imgur.com',
    'https://img85.pixhost.to'
  ];
  try {
    const urlObj = new URL(url);
    return !validDomains.some(domain => urlObj.href.startsWith(domain));
  } catch (error) {
    return false;
  }
}

export const convertirFecha = fecha => {
  const partes = fecha.split('-');
  const dia = partes[0];
  const mes = partes[1];
  const anio = partes[2];
  return `${anio}-${mes}-${dia}`; // Convertir a formato "YYYY-MM-DD"
};

export function ordenarPorPasteID(array) {
  return array.sort((a, b) => {
    const pasteID1 = parseInt(a.pasteID);
    const pasteID2 = parseInt(b.pasteID);
    return pasteID1 - pasteID2;
  });
}

export function getFirstYouTubeVideoLink(links) {
  for (const link of links) {
    if (isYouTubeLink(link?.iframeSrc)) {
      return link?.iframeSrc;
    }
  }
  return null;
}

function isYouTubeLink(link) {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\//;
  return youtubeRegex.test(link);
}

// Función para convertir bytes a GB
export function bytesToGB(bytes, toFixed = 2) {
  const gigabyte = 1073741824; // 1 GB = 1,073,741,824 bytes
  const fileSizeInGB = bytes / gigabyte;
  return fileSizeInGB.toFixed(toFixed); // Redondear el resultado a dos decimales
}

export function obtenerTamanoAproximado(
  tamanoTotalGB,
  numeroArchivos,
  subtitle = 'Capítulo'
) {
  // Calcular el tamaño aproximado por archivo
  const tamanoPorArchivo = tamanoTotalGB / numeroArchivos;

  // Calcular el límite inferior del rango redondeando hacia abajo
  const limiteInferior = Math.floor(tamanoPorArchivo);

  // Calcular el límite superior del rango redondeando hacia arriba
  const limiteSuperior = Math.ceil(tamanoPorArchivo);

  // Crear la etiqueta del rango
  // const etiqueta = `${limiteInferior} GB a ${limiteSuperior} GB Aprox./Capítulo`;
  const etiqueta = `${limiteSuperior} GB Aprox./${subtitle}`;

  return etiqueta;
}

export function objectToCustomString(obj) {
  let result = '';

  function processObject(obj, prefix = '') {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        processObject(obj[key], `${prefix}${key}.`);
      } else {
        result += `${key}: ${obj[key]}\n`;
      }
    }
  }

  processObject(obj);
  return result;
}

export function extraerParametros(objeto) {
  let arrayMediaInfo = [];
  // Busca el objeto "General" dentro de la matriz "track"
  const general = objeto.media.track.find(
    track => track['@type'] === 'General'
  );

  // Busca el objeto "Video" dentro de la matriz "track"
  const video = objeto.media.track.find(track => track['@type'] === 'Video');
  const audio = extraerParametrosDeAudio(objeto);
  const text = extraerParametrosDeText(objeto);

  // Verifica si se encontraron los objetos General y Video
  if (general) {
    // Extrae los valores de los parámetros requeridos
    const generalType = `@type: ${general['@type']}`;
    const generalFormat = `Format: ${general.Format}`;
    const generalFileSize = `FileSize: ${bytesToGB(general.FileSize)} GB`;
    const generalDuration = `Duration: ${convertirSegundosAHora(
      general.Duration
    )}`;
    const generalTitle = `Title: ${general.Title}`;
    const generalMovie = `Movie: ${general.Movie}`;

    // Retorna los resultados como un arreglo de cadenas de texto
    // return [generalFormat, videoWidth, videoHeight].join('\n');
    arrayMediaInfo.push(
      generalType,
      generalFormat,
      generalFileSize,
      generalDuration,
      generalTitle,
      generalMovie
    );
  }

  if (video) {
    const videoType = `@type: ${video['@type']}`;
    const videoStreamOrder = `@type: ${video.StreamOrder}`;
    const videoFormat = `Format: ${video.Format}`;
    const videoCodecID = `CodecID: ${video.CodecID}`;
    const videoBitRate = `BitRate: ${video.BitRate}`;
    const videoBitRateMaximum = `BitRate_Maximum: ${video.BitRate_Maximum}`;
    const videoWidth = `Width: ${video.Width}`;
    const videoHeight = `Height: ${video.Height}`;
    const videoFrameRate = `FrameRate: ${video.FrameRate}`;

    arrayMediaInfo.push(
      videoType,
      videoStreamOrder,
      videoFormat,
      videoCodecID,
      videoBitRate,
      videoBitRateMaximum,
      videoWidth,
      videoHeight,
      videoFrameRate
    );
  }

  if (audio) {
    arrayMediaInfo.push(audio);
  }

  if (text) {
    arrayMediaInfo.push(text);
  }

  return arrayMediaInfo.join('\n').replaceAll(',', '');
}

function extraerParametrosDeAudio(objeto) {
  // Busca todos los objetos de tipo "Audio" dentro de la matriz "track"
  const pistasDeAudio = objeto.media.track.filter(
    track => track['@type'] === 'Audio'
  );

  // Verifica si se encontraron pistas de audio
  if (pistasDeAudio.length > 0) {
    // Crea un arreglo para almacenar los valores de los parámetros "Format"
    const formatosDeAudio = pistasDeAudio.map(
      audio =>
        `@type: ${audio['@type']}\n @typeorder: ${audio['@typeorder']}\n StreamOrder: ${audio.StreamOrder}\n Format: ${audio.Format}\n Format_Commercial_IfAny: ${audio.Format_Commercial_IfAny}\n Channels: ${audio.Channels}\n Title: ${audio.Title}\n Language: ${audio.Language}\n Default: ${audio.Default}\n Forced: ${audio.Forced}\n`
    );

    // Retorna el arreglo de formatos de audio
    return formatosDeAudio;
  } else {
    // Retorna un mensaje de error si no se encuentran pistas de audio
    return null;
  }
}
function extraerParametrosDeText(objeto) {
  // Busca todos los objetos de tipo "Audio" dentro de la matriz "track"
  const pistasDeText = objeto.media.track.filter(
    track => track['@type'] === 'Text'
  );

  // Verifica si se encontraron pistas de audio
  if (pistasDeText.length > 0) {
    // Crea un arreglo para almacenar los valores de los parámetros "Format"
    const formatosDeText = pistasDeText.map(
      text =>
        `@type: ${text['@type']}\n @typeorder: ${text['@typeorder']}\n Format: ${text.Format}\n Title: ${text.Title}\n Language: ${text.Language}\n Default: ${text.Default}\n Forced: ${text.Forced}\n`
    );

    // Retorna el arreglo de formatos de text
    return formatosDeText;
  } else {
    // Retorna un mensaje de error si no se encuentran pistas de audio
    return null;
  }
}

export function convertirSegundosAHora(durationInSeconds) {
  const horas = Math.floor(durationInSeconds / 3600);
  const minutos = Math.floor((durationInSeconds % 3600) / 60);

  let formato = '';
  if (horas > 0) {
    formato += `${horas} H `;
  }
  if (minutos > 0) {
    formato += `${minutos} MIN`;
  }

  return formato;
}

export function extracNameAudio(object) {
  if (!object) return null;
  const pistasDeAudio = object.media.track.filter(
    track => track['@type'] === 'Audio'
  );
  let arrayNameAudio = [];
  let nameAudio = '';

  if (pistasDeAudio.length > 0) {
    for (let i = 0; i < pistasDeAudio.length; i++) {
      nameAudio = `${pistasDeAudio[i]?.Title} ${
        pistasDeAudio[i]?.Format
      } ${changeChannel(pistasDeAudio[i]?.Channels)} ${
        pistasDeAudio[i]?.Format_Commercial_IfAny
      }`;
      arrayNameAudio.push(nameAudio);
    }
    return arrayNameAudio;
  } else {
    return null;
  }
}
export function extracNameText(object) {
  if (!object) return null;
  const pistasDeText = object.media.track.filter(
    track => track['@type'] === 'Text'
  );
  let arrayNameText = [];
  let nameAudio = '';

  if (pistasDeText.length > 0) {
    for (let i = 0; i < pistasDeText.length; i++) {
      nameAudio = `${pistasDeText[i]?.Title} ${changeLanguageText(
        pistasDeText[i]?.Language
      )} ${changeFormatText(pistasDeText[i]?.Format)}`;
      arrayNameText.push(nameAudio);
    }
    return arrayNameText;
  } else {
    return null;
  }
}

function changeChannel(channel) {
  const CHANNELS = {
    1: '1.0',
    2: '2.0',
    6: '5.1',
    7: '6.1',
    8: '7.1'
  };

  return CHANNELS[channel] || channel;
}
function changeFormatText(format) {
  const FORMAT = {
    'UTF-8': 'SRT',
    'UTF-8 Plain Text': 'SRT',
    'UTF-8 with BOM': 'SRT',
    'UTF-8 Plain Text with BOM': 'SRT',
    'UTF-8 Text': 'SRT',
    'UTF-8 Text with BOM': 'SRT'
  };

  return FORMAT[format] || format;
}
function changeLanguageText(language) {
  return language ? `(${language})` : '';
}

// Convierte la URL blob en un archivo
export const convertBlobToImageFile = async (
  blobImageUrl,
  name = 'imagen.png'
) => {
  const response = await fetch(blobImageUrl);
  const blob = await response.blob();
  return new File([blob], name); // Cambia 'nombre_de_archivo.png' al nombre deseado
};

export const createObjectIconServer = servers => {
  if (servers?.length === 0) return [];
  let arrayServers = [];
  for (let i = 0; i < servers?.length; i++) {
    arrayServers.push({
      _id: servers[i].name.replace(/\s/g, ''),
      name: servers[i].name.replace(/\s/g, ''),
      img: `https://www.google.com/s2/favicons?domain=${servers[i].url}`,
      img2: servers[i].url,
      value: servers[i].id
    });
  }

  return arrayServers;
};

export const createObjectUrlImagesPaste = servers => {
  if (servers?.length === 0) return {};
  let arrayServers = {};
  for (let i = 0; i < servers?.length; i++) {
    arrayServers[servers[i].id] = servers[i].url_image_free;
  }
  return arrayServers;
};
export const createObjectUrlImagesPasteVip = servers => {
  if (servers?.length === 0) return {};
  let arrayServers = {};
  for (let i = 0; i < servers?.length; i++) {
    arrayServers[servers[i].id] = servers[i].url_image_vip;
  }
  return arrayServers;
};

export const createArrayServersToStep3 = servers => {
  if (servers?.length === 0) return [];
  let arrayServers = [];
  for (let i = 0; i < servers?.length; i++) {
    arrayServers.push({
      id: servers[i].url,
      servidor: servers[i].name
    });
  }

  return arrayServers;
};

export function arrayObjectsToString(arrayOfObjects) {
  if (!arrayOfObjects) return '';
  // Utilizamos el método map para convertir cada objeto a un string
  let arrayOfStrings = arrayOfObjects?.map(function (obj) {
    // Supongamos que cada objeto tiene propiedades 'nombre' y 'edad'
    // return '◼️ ' + obj.title_fecha;
    return (
      '<img src="https://i.ibb.co/410rS0H/icons8-download-from-the-cloud.gif" alt="descargar" width="20px" /> ' +
      obj.title_fecha
    );
  });

  // Finalmente, utilizamos el método join para concatenar los strings con un separador (por ejemplo, una coma)
  let resultString = arrayOfStrings.join('\n');

  return resultString;
}
export function arrayObjectsToSeasons(arrayOfObjects) {
  if (!arrayOfObjects) return '';
  // const inProduction = arrayOfObjects?.status !== 'Ended';
  // Utilizamos el método map para convertir cada objeto a un string
  let arrayOfStrings = arrayOfObjects?.seasons_info?.map(function (obj, i) {
    if (obj.checked) {
      if (
        arrayOfObjects.number_of_seasons === i + 1 &&
        arrayOfObjects?.last_episode_to_air?.episode_number < obj.episode_count
      ) {
        return (
          '<img src="https://i.ibb.co/VTTsYy6/icons8-live-video-on.gif" alt="descargar" width="20px" /> ' +
          obj?.name +
          ' ' +
          obj?.title_episodios +
          ' (En emisión)'
        );
      } else {
        return (
          '<img src="https://i.ibb.co/410rS0H/icons8-download-from-the-cloud.gif" alt="descargar" width="20px" /> ' +
          obj?.name +
          ' ' +
          obj?.title_episodios
        );
      }
    }
    return '';
  });

  // Finalmente, utilizamos el método join para concatenar los strings con un separador (por ejemplo, una coma)
  const filteredArray = arrayOfStrings.filter(item => item !== '');
  let resultString = filteredArray?.join('\n');

  return resultString;
}

export function obtenerYearColecciones(array) {
  return (
    array?.parts[0]?.release_date?.slice(0, 4) +
    '-' +
    array?.parts[array?.parts?.length - 1]?.release_date?.slice(0, 4)
  );
}
export function crearArrayValuesColeccion(array) {
  for (let i = 0; i < array?.parts?.length; i++) {
    array.parts[i].title_fecha =
      array.parts[i].title +
      ' ' +
      `(${array.parts[i].release_date?.slice(0, 4)})`;
  }
  return array?.parts;
}
export function crearArrayValuesSeasons(array) {
  for (let i = 0; i < array?.seasons?.length; i++) {
    array.seasons[i].title_episodios =
      array?.last_episode_to_air?.season_number ===
      array.seasons[i].season_number
        ? `Episodios ${array.last_episode_to_air?.episode_number}/${
            array.seasons[i].episode_count
          } (${array.seasons[i].air_date?.slice(0, 4)})`
        : `Episodios ${array.seasons[i].episode_count}/${
            array.seasons[i].episode_count
          } (${array.seasons[i].air_date?.slice(0, 4)})`;
    array.seasons[i].checked = true;

    //obtener el episode_number_string del ultimo episodio
    array.seasons[i].episode_number_string =
      array?.last_episode_to_air?.season_number ===
      array.seasons[i].season_number
        ? `${array.last_episode_to_air?.episode_number}/${array.seasons[i].episode_count}`
        : `${array.seasons[i].episode_count}/${array.seasons[i].episode_count}`;
  }
  return array?.seasons;
}

//obtener el menor y mayor número de capítulos de todas las temporadas (excluyendo especiales)
export function obtenerEpisodeNumberString(array) {
  if (!array || array.length === 0) return;

  // Filtrar temporadas especiales (season_number = 0)
  const seasonEpisodes = array.filter(item => item.season_number !== 0);

  if (seasonEpisodes.length === 0) return;

  // Obtener todos los números de episodios de temporadas regulares
  const episodeNumbers = seasonEpisodes.map(item =>
    parseInt(item.episode_number_string)
  );

  // Encontrar el menor y mayor número
  const minEpisodes = Math.min(...episodeNumbers);
  const maxEpisodes = Math.max(...episodeNumbers);

  return `${minEpisodes}/${maxEpisodes}`;
}

//obtener el season_number del ultimo episodio
export function obtenerSeasonNumber(array) {
  if (!array) return;
  return array[array.length - 1].season_number;
}
//obtener el season_number de toddas las temporadas
export function obtenerStringSeasonNumber(array) {
  if (!array) return;
  return array.map(a => a.season_number).join(', ');
}

export function extraerTamano(mystring) {
  if (!mystring) return;
  const regex = /Tamaño:\s*<b>(.*?)<\/b>/;
  const match = mystring.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return 'Tamaño no encontrado';
  }
}

export function extraerDominiosServidores(htmlString) {
  // Encuentra la posición del texto "Servidores"
  if (htmlString === undefined) return [];
  var indexServidores = htmlString.indexOf('Servidores');

  // Si no se encuentra "Servidores", devuelve un array vacío
  if (indexServidores === -1) {
    return [];
  }

  // Corta la cadena después de la sección "Servidores"
  var htmlServidores = htmlString.substring(indexServidores);

  // Expresión regular para encontrar todos los enlaces dentro de la sección "Servidores"
  var regex = /<img decoding="async" src="(https?:\/\/[^"]+)"/g;

  // Encuentra todos los elementos que coinciden con la expresión regular dentro de la sección "Servidores"
  var matches = htmlServidores.matchAll(regex);

  // Array para almacenar los enlaces extraídos
  var enlaces = [];

  // Itera sobre las coincidencias y extrae los enlaces
  for (const match of matches) {
    enlaces.push(match[1].split('?domain=')[1]);
  }

  // Retorna los enlaces extraídos
  return enlaces;
}

export function crearArrayDeTags(tags, metadata, name) {
  if (tags.length === 0 && tags.length === undefined) return [];
  let arrayTags = [];
  tags?.forEach(item => {
    const filteredItems = filterByQuality(
      item.name.toUpperCase(),
      metadata,
      name
    );
    if (
      filteredItems?.length > 0 &&
      filteredItems[0]?.label.toUpperCase() === item.name.toUpperCase()
    ) {
      arrayTags.push(item);
    }
  });
  return arrayTags;
}

// export function insertarCodigoHTML(htmlString, capitulosDisponibles) {
//     // Crear un elemento div temporal para contener el HTML proporcionado
//     var tempDiv = document.createElement('div');
//     tempDiv.innerHTML = htmlString.trim();

//     // Obtener el div con la clase "sipno" del HTML proporcionado
//     var sipnoDiv = tempDiv.querySelector('.sipno');

//     if (sipnoDiv) {
//         // Crear un nuevo elemento div
//         var nuevoDiv = document.createElement('div');
//         nuevoDiv.className = 'capitulos-disponibles';

//         // Crear un nuevo elemento span
//         var nuevoSpan = document.createElement('span');
//         nuevoSpan.textContent = `Episodio ${capitulosDisponibles} disponible`;

//         // Agregar el nuevo elemento span al nuevo elemento div
//         nuevoDiv.appendChild(nuevoSpan);

//         // Insertar el nuevo elemento div antes del elemento con la clase "sinopsis5"
//         sipnoDiv.insertBefore(nuevoDiv, sipnoDiv.querySelector('.sinopsis5'));

//         // Devolver el HTML modificado
//         return tempDiv.innerHTML;
//     } else {
//         // Si no se encuentra el div con la clase "sipno", devolver el HTML original
//         return htmlString;
//     }
// }

// Función para reemplazar el código existente si se encuentra
function replaceIfExists(original, newString) {
  let startIndex = original.indexOf('<div class="capitulos-disponibles">');
  if (startIndex !== -1) {
    let endIndex = original.indexOf('</div>', startIndex);
    return (
      original.slice(0, startIndex) + newString + original.slice(endIndex + 6)
    );
  } else {
    let position = original.indexOf('</div>') + 6;
    let finalString =
      original.slice(0, position) + newString + original.slice(position);
    return finalString;
  }
}

export function insertarCodigoHTML(originalString, capitulo) {
  let finalString;
  if (capitulo === 'Texto Libre') {
    let startIndex = originalString.indexOf(
      '<div class="capitulos-disponibles">'
    );
    if (startIndex !== -1) {
      let endIndex = originalString.indexOf('</div>', startIndex);
      finalString =
        originalString.slice(0, startIndex) +
        originalString.slice(endIndex + 6);
    } else {
      finalString = originalString;
    }
  } else {
    let newString = `<div class="capitulos-disponibles"><span>${capitulo}</span></div>`;
    finalString = replaceIfExists(originalString, newString);
  }

  return finalString;
}

export function capturarNumeroCapitulo(texto) {
  // Expresión regular para encontrar el número después de "Capitulo "
  var regex = /Capítulo (\d+)/;
  // Ejecutar la expresión regular en el texto proporcionado
  var match = regex.exec(texto);
  // Verificar si se encontró una coincidencia y devolver el número capturado
  if (match && match[1]) {
    return parseInt(match[1]);
  } else {
    return ''; // Retornar null si no se encontró ninguna coincidencia
  }
}

export function extraerTextoCapitulosDisponibles(string) {
  // Expresión regular para buscar el div con la clase "capitulos-disponibles"
  const regex =
    /<div class="capitulos-disponibles"><span>(.*?)<\/span><\/div>/s;
  // Ejecutar la expresión regular en el string
  const match = regex.exec(string);
  // Verificar si se encontró el div
  if (match && match[1]) {
    // Si se encontró, retornar el texto dentro del div
    return match[1].trim(); // Trim para eliminar espacios en blanco al inicio y al final
  } else {
    // Si no se encontró el div, retornar un mensaje indicando que no está disponible
    return '';
  }
}

export function extraerNumeroDesdeTexto(string) {
  // Expresión regular para buscar cualquier número dentro del texto
  const regex = /\d+/;
  // Ejecutar la expresión regular en el string
  const match = regex.exec(string);
  // Verificar si se encontró algún número
  if (match && match[0]) {
    // Si se encontró, retornar el número encontrado como entero
    return parseInt(match[0]);
  } else {
    // Si no se encontró ningún número, retornar null o algún valor que indique la ausencia de número
    return '';
  }
}

export function extraerTemporada(mainString) {
  const temporadaRegex = /Temporada:\s*<b>(\d+)<\/b>/i;
  const temporadaMatch = mainString?.match(temporadaRegex);
  const temporada = temporadaMatch ? parseInt(temporadaMatch[1]) : null;

  return temporada || null;
}
export function extraerCapitulos(mainString) {
  const capitulosRegex = /Capitulos:\s*<b>(\d+)<\/b>/i;
  const capitulosMatch = mainString?.match(capitulosRegex);
  const capitulos = capitulosMatch ? parseInt(capitulosMatch[1]) : null;

  return capitulos;
}

export function extraerTextoMasInfo(htmlString) {
  if (htmlString === undefined) return null;
  // Expresión regular para buscar el texto dentro del div con la clase 'masinfo5'
  const regex = /<div\sclass="masinfo5">(.*?)<\/div>/s;
  const match = regex.exec(htmlString);

  // Verificar si se encontró una coincidencia
  if (match && match.length > 1) {
    // Devolver el texto encontrado
    return match[1].trim();
  } else {
    return null;
  }
}

export function extraerTemporadasYCapítulos(string) {
  // Expresión regular para buscar las líneas que contienen información de temporadas y capítulos
  const regexTemporadas =
    /Temporada: *(?:<b>)?([^<]+)(?:<\/b>)?[^]*?Capitulos: *(?:<b>)?([^<]+)(?:<\/b>)?/;

  // Buscar coincidencias en el string dado
  const match = string?.match(regexTemporadas);

  if (!match) {
    return null; // Devolver null si no se encuentra ninguna coincidencia
  }

  // Extraer las temporadas y capítulos encontrados
  const temporadas = match[1]?.trim();
  const capitulos = match[2]?.trim();

  return { temporadas, capitulos };
}

export function extraerSize(string) {
  if (string === undefined) return null;
  // Expresión regular para buscar la línea que contiene información sobre el tamaño
  const regexsize = /Tamaño: *(?:<b>)?([^<]+)(?:<\/b>)?/;

  // Buscar coincidencias en el string dado
  const match = string.match(regexsize);

  if (!match) {
    return null; // Devolver null si no se encuentra ninguna coincidencia
  }

  // Extraer el size encontrado
  const size = match[1].trim();

  return size;
}

export function sumarPeso(pesoTotal, pesoAdicional) {
  let nuevoPesoTotal;
  // Verificar si el peso total contiene información adicional
  if (pesoTotal.includes('Total:')) {
    // Extraer el peso total del string usando expresiones regulares
    const regex = /Total: (\d+(\.\d+)?) GB/;
    const matches = pesoTotal.match(regex);
    if (matches && matches.length >= 2) {
      const pesoTotalNumerico = parseFloat(matches[1]);
      // Sumar el peso adicional al peso total
      nuevoPesoTotal =
        (pesoTotalNumerico + parseFloat(pesoAdicional)).toFixed(2) + ' GB';
    }
  } else {
    // Si el peso viene en formato directo, simplemente lo sumamos con el peso adicional
    const pesoTotalNumerico = parseFloat(pesoTotal);
    nuevoPesoTotal =
      (pesoTotalNumerico + parseFloat(pesoAdicional)).toFixed(2) + ' GB';
  }

  // Reemplazar el peso total en la cadena original
  return pesoTotal.replace(
    /Total: (\d+(\.\d+)?) GB/,
    'Total: ' + nuevoPesoTotal
  );
}

export function modificarSize(string, nuevoSize) {
  // Expresión regular para buscar la línea que contiene información sobre el tamaño
  const regexTamaño = /Tamaño: *(?:<b>)?([^<]+)(?:<\/b>)?/;

  // Reemplazar el tamaño actual con el nuevo tamaño
  const nuevoString = string.replace(
    regexTamaño,
    `Tamaño: <b>${nuevoSize}</b>`
  );

  return nuevoString;
}

export function modificarContenidoMasinfo5(html, nuevoValor) {
  // Usamos una expresión regular para buscar el contenido dentro del div con la clase masinfo5
  const regex = /(<div class="masinfo5">)([\s\S]*?)(<\/div>)/;
  // Reemplazamos el contenido antiguo con el nuevo valor
  const nuevoHTML = html?.replace(regex, `$1${nuevoValor}$3`);
  return nuevoHTML;
}

export function modificarContenidoCapitulos(html, nuevoValor) {
  // Usamos una expresión regular para buscar el contenido dentro del párrafo que contiene los capítulos
  const regex = /(<p>Capitulos: )(?:<b>)?([\s\S]*?)(?:<\/b>)?(<\/p>)/;
  // Reemplazamos el contenido antiguo con el nuevo valor
  const nuevoHTML = html.replace(regex, `$1<b>${nuevoValor}</b>$3`);
  return nuevoHTML;
}

export function modificarContenidoTemporada(html, nuevoValor) {
  // Usamos una expresión regular para buscar el contenido dentro del párrafo que contiene los capítulos
  const regex = /(<p>Temporada: )(?:<b>)?([\s\S]*?)(?:<\/b>)?(<\/p>)/;
  // Reemplazamos el contenido antiguo con el nuevo valor
  const nuevoHTML = html.replace(regex, `$1<b>${nuevoValor}</b>$3`);
  return nuevoHTML;
}

export function reemplazarURLPrincipal(string, nuevaURL) {
  if (!string) return string;
  // Expresión regular para encontrar la URL principal en el string
  const regex = /src="([^"]+)"/;

  // Reemplazar la URL principal con la nueva URL proporcionada
  const nuevoString = string.replace(regex, `src="${nuevaURL}"`);

  return nuevoString || string;
}

export const renderUrl = url => {
  if (url) {
    return `https://image.tmdb.org/t/p/w600_and_h900_bestv2${url}`;
  }
  return '/HDLATINO-LOGO.png';
};

export function cuentaRegresiva(segundaHora) {
  const horaActual = new Date();
  const segundaHoraDate = new Date(segundaHora);

  // Restar una hora a segundaHoraDate
  segundaHoraDate.setHours(segundaHoraDate.getHours() - 1);

  if (horaActual.getTime() > segundaHoraDate.getTime()) {
    return null;
  }

  const diferenciaTiempo = segundaHoraDate.getTime() - horaActual.getTime();

  const dias = Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24));
  const horas = Math.floor(
    (diferenciaTiempo % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutos = Math.floor(
    (diferenciaTiempo % (1000 * 60 * 60)) / (1000 * 60)
  );
  const segundos = Math.floor((diferenciaTiempo % (1000 * 60)) / 1000);

  return {
    dias,
    horas,
    minutos,
    segundos
  };
}

export function eliminarPrimeraI(str) {
  // Si la cadena no tiene al menos 2 caracteres, no hay nada que eliminar
  if (str.length < 2) {
    return str;
  }

  // Convertimos la cadena a un array de caracteres
  const caracteres = str.split('');

  // Si la segunda letra es un número...
  if (/[0-9]/.test(caracteres[1])) {
    // Eliminamos la primera letra
    caracteres.shift();
  }

  // Convertimos el array de caracteres de nuevo a una cadena
  return caracteres.join('');
}

export function obtenerLabelResolucion(resolucion) {
  return metadata
    .filter(item => item.name === 'Resolucion')[0]
    .data.find(ii => ii.value === resolucion).label;
}

// OSCURECER UN COLOR

export function bajarTonalidad(color, factor) {
  // Convertir el color hexadecimal a valores RGB
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  // Aplicar el factor de reducción a cada componente RGB
  r = Math.floor(r * factor);
  g = Math.floor(g * factor);
  b = Math.floor(b * factor);

  // Asegurar que los valores estén en el rango [0, 255]
  r = Math.min(Math.max(r, 0), 255);
  g = Math.min(Math.max(g, 0), 255);
  b = Math.min(Math.max(b, 0), 255);

  // Convertir los valores RGB de nuevo a color hexadecimal
  let resultado =
    '#' +
    (r < 16 ? '0' : '') +
    r.toString(16) +
    (g < 16 ? '0' : '') +
    g.toString(16) +
    (b < 16 ? '0' : '') +
    b.toString(16);

  return resultado;
}
// validar que sea un JSON
export function esJSON(variable) {
  try {
    const json = atob(variable);
    const res = JSON.parse(json);
    return res;
  } catch (error) {
    return variable;
  }
}

export function obtenerParametrosURLsClasificados(stringHTML) {
  const regex = /<a[^>]*id="(?:link5free|link5vip)"[^>]*href="([^"]+)"/g;
  const parametros = [];
  let match;
  while ((match = regex.exec(stringHTML)) !== null) {
    const url = match[1];
    const parametro = url.match(/(?<=\?v=)[^&?#]+/);
    if (parametro) {
      const tipo = match[0].includes('link5free')
        ? 'free'
        : match[0].includes('link5vip')
        ? 'vip'
        : null;
      parametros.push({ parametro: parametro[0], tipo: tipo });
    }
  }
  return parametros;
}

export function obtenerPasteID(parametros, pasteData) {
  const resultado = [];
  for (const parametro of parametros) {
    for (const paste of pasteData) {
      if (parametro.parametro === paste.url) {
        resultado.push({
          pasteid: paste.pasteID,
          type: parametro.tipo
        });
        break; // Una vez encontrado el valor, se puede salir del bucle interno
      }
    }
  }
  return resultado;
}

// Función para agrupar los datos por 'referer'
export const groupByReferer = array => {
  return array.reduce((acc, obj) => {
    const key = obj?.referer || obj?.enlace;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key]?.push(obj);
    return acc;
  }, {});
};

const obtenerResolucion = value => {
  const resoluciones = metadata.find(item => item.name === 'Resolucion').data;
  const resolucion = resoluciones.find(res => res.value === value);
  return resolucion ? resolucion.label : 'Resolución no encontrada';
};

export function crearNombrePaste(datos) {
  const productoras = {
    NETFLIX: 'NTFX',
    PRIMEVIDEO: 'AMNZ',
    DISNEP: 'DSNY',
    DISNEY: 'DSNY',
    PARAMOUNT: 'PRMNT',
    'APPLE-TV': 'APTV',
    UNIVERSAL: 'UNVS+',
    STARPLUS: 'STAR+',
    CRUNCHYROLL: 'CR'
  };

  const extra = datos?.Extra === 'ninguna' ? '' : datos?.Extra;
  const year = datos?.movieDetails?.year ? `(${datos.movieDetails.year})` : '';
  const title = datos?.movieDetails?.title || datos?.movieDetails?.name || '';
  // const idioma = datos?.extensionIdioma ? `[${datos.extensionIdioma}]` : '';

  // const arrayIdioma = datos?.FormatoAudioMediainfo?.map(i => i.value);
  // const idioma = datos?.FormatoAudioMediainfo?.length
  //     ? `[${arrayIdioma.join('-')}]`
  //     : '';

  const idioma = datos?.audioTitles ? datos?.audioTitles : '';

  const extension = datos?.extension || '';

  const abreviaturaProductora = extra ? `{${productoras[extra] || extra}}` : '';

  const calidadWebdl = datos?.Calidad ? `${datos.Calidad}` : '';
  const calidad = calidadWebdl === 'WEBDL' ? 'WEB-DL' : calidadWebdl;

  const resolucion = datos?.Resolucion
    ? `[${obtenerResolucion(datos.Resolucion)}]`
    : '';

  const parts = [
    title,
    year,
    resolucion,
    abreviaturaProductora,
    calidad,
    extension,
    idioma
  ];
  const nombreArchivo = parts.filter(Boolean).join(' ');

  return `${nombreArchivo} `;
}

export function isValidURL(url) {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return regex.test(url);
}

export function buildBracketStringFromTitles(data) {
  const firstWords = [];

  // Recorrer el array "track" para encontrar los objetos de tipo "Audio"
  data?.media?.track?.forEach(track => {
    if (track['@type'] === 'Audio' && track.Title) {
      // Tomar la primera palabra antes del primer espacio del título
      const firstWord = track?.Title?.split(' ')[0];
      // Reemplazar "Español" por "Latino" si es necesario
      const word = firstWord.toLowerCase().includes('español')
        ? 'Latino'
        : firstWord;
      firstWords.push({
        word,
        isLatino: isLatinoOrCastellano(word)
      });
    }
  });

  // Ordenar el array de palabras para que las palabras latinas o castellanas vayan primero
  firstWords.sort((a, b) => {
    if (a.isLatino && !b.isLatino) {
      return -1;
    } else if (!a.isLatino && b.isLatino) {
      return 1;
    } else {
      return 0;
    }
  });

  // Unir las primeras palabras con comas y envolverlas en corchetes
  return `[${firstWords.map(item => item.word).join('-')}]`;
}

// Función auxiliar para determinar si una palabra es latina o castellana
function isLatinoOrCastellano(word) {
  const latinoOrCastellanoWords = ['Latino', 'Castellano', 'Latín'];
  return latinoOrCastellanoWords.some(item =>
    word.toLowerCase().includes(item.toLowerCase())
  );
}

export const checkApartado = (values, apartados, movie) => {
  const apartadoExistente = apartados?.find(
    apartado =>
      apartado.apar_tmdb === movie.id &&
      apartado.apar_calidad === values.Calidad &&
      apartado.apar_resolucion === values.Resolucion &&
      apartado.apar_idioma === values.Idioma
  );

  if (!apartadoExistente) return true;

  const expiracionDate = moment(apartadoExistente.apar_expiration_date);
  const ahora = moment();
  const horasTranscurridas = ahora.diff(expiracionDate, 'hours');
  const h = Math.abs(horasTranscurridas);

  // si han pasado menos de 3 horas
  if (
    h < HORA_FINALIZAR &&
    apartadoExistente.apar_uploader === userData?.alias
  ) {
    return true;
  }
  // si han pasado mas de 3 horas
  if (
    h > HORA_FINALIZAR &&
    apartadoExistente.apar_uploader === userData?.alias
  ) {
    toast.error('Tiempo de publicacion expirado', {
      autoClose: 10000
    });
    return false;
  }

  // si han pasado mas de 3 horas y no es el uploader
  if (
    h > HORA_FINALIZAR &&
    apartadoExistente.apar_uploader !== userData?.alias
  ) {
    // toast.error(
    //     `Este post está apartado por ${
    //         apartadoExistente.apar_uploader
    //     } por las próximas ${3 - h} horas`,
    //     {
    //         autoClose: 10000
    //     }
    // );
    return true;
  }

  return false;
};

export const formatFileSize = bytes => {
  if (bytes < 1073741824) {
    console.log('MATh', `${Math.round(bytes / 1048576)} MB`);
    return `${Math.round(bytes / 1048576)} MB`;
  }
  console.log('GB', `${bytesToGB(bytes)} GB`);
  return `${bytesToGB(bytes)} GB`;
};

export const formatearGBColeccion = valor => {
  const match = valor.match(/(\d+)\s*GB/); // Solo busca "GB"
  if (match) {
    const numero = match[1];
    return `${parseFloat(numero).toFixed(2)} GB`;
  }
  return valor;
};

export const redondearSize = bytes => {
  if (bytes < 1024) {
    return `${bytes} B`; // Bytes
  } else if (bytes < 1048576) {
    const kbSize = Math.round(bytes / 1024);
    return `${kbSize} KB`; // Kilobytes
  } else if (bytes < 1073741824) {
    const mbSize = bytes / 1048576;
    if (mbSize >= 100) {
      // Redondear a la centena de abajo
      const roundedSize = Math.floor(mbSize / 100) * 100;
      return `${roundedSize} MB`; // Megabytes redondeados
    } else {
      // Mostrar el tamaño en MB sin redondeo hacia abajo
      const roundedSize = Math.floor(mbSize);
      return `${roundedSize} MB`; // Megabytes sin redondeo
    }
  } else {
    const gbSize = bytes / 1073741824;
    // Redondear a la unidad de abajo
    const roundedSize = Math.floor(gbSize);
    return `${roundedSize} GB`; // Gigabytes
  }
};

export function extraerEnlacesDinamicos(html) {
  if (!html) return [];

  // Expresión regular mejorada para capturar enlaces dinámicos (independiente de la estructura del HTML)
  const regex = /https:\/\/vip\.hdlatino\.us\/pastes\/\?v=[A-Za-z0-9]{2,3}/g;

  // Buscar coincidencias en el HTML
  const enlaces = html.match(regex);

  // Si no se encuentran enlaces, devolver un array vacío
  if (!enlaces) return [];

  // Eliminar duplicados y devolver el resultado
  return [...new Set(enlaces)];
}

export const decodeHtmlEntities = text => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};
