import { useState } from 'react';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { useEffect } from 'react';
import {
  bytesToGB,
  obtenerEpisodeNumberString,
  obtenerStringSeasonNumber,
  obtenerTamanoAproximado,
  formatFileSize,
  redondearSize
} from '../../utils/functions';

const useSeason = () => {
  const { movie, dataAEnviar } = useStore();
  const dispatch = useDispatch();
  const [seasons, setSeasons] = useState(movie?.seasons || []);
  const [sizeAll, setSizeAll] = useState(0);
  const [bitesAll, setBitesAll] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [totalSize, setTotalSize] = useState('');
  const [temporada, setTemporada] = useState('1');
  const [capitulos, setCapitulos] = useState('');

  const handleChange = (index, newValue) => {
    // Crear una copia profunda del estado actual
    const updatedMovie = JSON.parse(JSON.stringify(movie));

    // Asegurarnos que seasons existe
    if (!updatedMovie.seasons) {
      updatedMovie.seasons = [];
    }

    // Asegurarnos que el objeto en el índice existe
    if (!updatedMovie.seasons[index]) {
      updatedMovie.seasons[index] = {};
    }

    // Actualizar el valor del input específico
    updatedMovie.seasons[index].title_episodios = newValue;

    // Actualizar también el estado local de seasons
    const updatedSeasons = [...seasons];
    updatedSeasons[index] = {
      ...updatedSeasons[index],
      title_episodios: newValue
    };
    setSeasons(updatedSeasons);

    // Actualizar el estado global
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        seasons_info: updatedMovie.seasons
      }
    });
  };

  const handleChangeDataAEnviar = (newValue, newKey) => {
    newKey === 'temporada' && setTemporada(() => newValue);
    newKey === 'capitulo' && setCapitulos(() => newValue);
    newKey === 'PesoArchivo' && setTotalSize(() => newValue);
    dispatch({
      type: types.DATAAENVIAR,
      payload: { [newKey]: newValue }
    });
  };

  const handleChangePoster = newValue => {
    // Crear una copia del estado actual de movie para no mutar el estado directamente

    const poster_path = `https://www.themoviedb.org/t/p/original${newValue}`;
    const poster_path_respaldo = `https://www.themoviedb.org/t/p/original${newValue}`;

    // Actualizar el estado utilizando la función setMovie
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        poster_path,
        poster_path_respaldo
      }
    });
  };

  const handleCheckboxChange = (index, serie) => {
    // Asegúrate de que movie?.seasons_info y movie?.seasons existan
    const seasonsInfo =
      movie?.seasons_info?.length > 0 ? movie.seasons_info : movie.seasons;

    // Crea una copia profunda de seasonsInfo
    const updatedSeasonsInfo = JSON.parse(JSON.stringify(seasonsInfo));

    // Crea una copia profunda de seasons
    const updatedSeasonsSerie = JSON.parse(JSON.stringify(seasons));

    console.log('updatedSeasonsSerie', updatedSeasonsSerie);

    // Busca la serie correspondiente en updatedSeasonsInfo por nombre
    const existingSeasonIndex = updatedSeasonsInfo.findIndex(
      season => season.name === serie.name
    );

    // Actualiza el estado 'checked' de la serie en seasons
    const updatedSeasons = updatedSeasonsInfo.map((season, i) => {
      if (i === existingSeasonIndex) {
        return {
          ...season,
          checked: !season.checked
        };
      }
      return season;
    });

    // Filtra las series marcadas como 'checked' y actualiza seasons_info
    const updatedSeasonsInfoFiltered = updatedSeasons.filter(
      season => season.checked
    );

    console.log('serie', serie);

    // Si la serie no está en seasons_info, agrégala en la posición original
    if (existingSeasonIndex === -1) {
      updatedSeasonsInfoFiltered.splice(index, 0, {
        ...serie,
        checked: !serie.checked
      });
    }

    // Actualiza el estado 'checked' de la serie en updatedSeasonsSerie
    updatedSeasonsSerie[index] = {
      ...updatedSeasonsSerie[index],
      checked: !updatedSeasonsSerie[index].checked
    };

    setSeasons(updatedSeasonsSerie);
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        seasons_info: updatedSeasonsInfoFiltered
      }
    });
  };

  //capturar pesos de temporadas
  const handleChangeSize = (index, e) => {
    const files = e.target.files;
    let sizeBites = 0;

    // Obtener tamaños de los archivos actuales
    const currentFileSizes = [];
    for (let i = 0; i < files?.length; i++) {
      const fileSize = files[i].size;
      sizeBites += fileSize;
      currentFileSizes.push(fileSize);
    }

    setTotalFiles(prev => prev + files?.length);
    setBitesAll(prev => prev + sizeBites);
    const size = formatFileSize(sizeBites);

    // Actualizar el estado con el nuevo archivo
    const updatedMovie = { ...movie };
    const newValue = `${updatedMovie.seasons[index].title_episodios} Peso: ${size}`;
    handleChange(index, newValue);

    // Mantener un registro de todos los tamaños de archivos individuales
    const allFileSizes = [...(movie.allFileSizes || []), ...currentFileSizes];
    updatedMovie.allFileSizes = allFileSizes;

    dispatch({
      type: types.MOVIE,
      payload: updatedMovie
    });
    // Calcular el tamaño mínimo y máximo de todos los archivos
    const minSize = redondearSize(Math.min(...allFileSizes));
    const maxSize = redondearSize(Math.max(...allFileSizes));
    const totalSizeFormatted = formatFileSize(bitesAll + sizeBites);

    const isSameSize =
      minSize === maxSize ? `${minSize}` : `${minSize} a ${maxSize}`;

    setTotalSize(
      `${isSameSize} Aprox./Capítulo (Total: ${totalSizeFormatted})`
    );
  };

  useEffect(() => {
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        seasons_info: seasons
      }
    });
    // setTemporada(obtenerStringSeasonNumber(seasons));
    setCapitulos(obtenerEpisodeNumberString(seasons));
  }, []);
  useEffect(() => {
    dispatch({
      type: types.MOVIE,
      payload: {
        ...movie,
        seasons_info: seasons
      }
    });
  }, [seasons]);

  useEffect(() => {
    dispatch({
      type: types.DATAAENVIAR,
      payload: {
        ...dataAEnviar,
        PesoArchivo: totalSize
      }
    });
  }, [totalSize]);

  return {
    handleChange,
    handleChangePoster,
    handleCheckboxChange,
    handleChangeSize,
    handleChangeDataAEnviar,
    setTemporada,
    seasons,
    movie,
    sizeAll,
    totalSize,
    temporada,
    capitulos
  };
};

export default useSeason;
