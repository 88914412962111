import React, { useEffect, useState } from 'react';
import {
  Col,
  Dropdown,
  InputGroup,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styles from '../../assets/css/step1.module.css';
import Mymetadata from '../../metadata.json';
import { Form as MyForm, Formik, Field } from 'formik';
import { useDispatch, useStore } from '../../store/StoreProvider';
import { types } from '../../store/storeReducer';
import { validationSchemaToPoster } from '../../config/validation-squema';

import FileUploader from '../../components/upload/FileUploader';
import { subirPoster } from '../../utils/subirimg';
import { FaRegImage, FaSearch, FaUpload } from 'react-icons/fa';
import { TbBrandNetflix } from 'react-icons/tb';
import { BsImages } from 'react-icons/bs';
import { SiCoveralls } from 'react-icons/si';
import FileUploaderUrl from '../upload/FileUploaderUrl';
import {
  crearPlantillaPoster,
  generarPoster,
  getPlantillas,
  getPlantillasPlus,
  deletePlantilla,
  subirPlantilla,
  deletePlantillaPlus,
  subirPlantillaPlus
} from '../../utils/api';
import Button from '../button/Button';
import GroupImages from '../GroupImages/GroupImages';
import Groupplantillas from '../GroupImages/Groupplantillas';
import ShowCapturas from '../Capturas/ShowCapturas';
import LayoutPlantillas from '../../pages/home/crearPortada/Plantillas/LayoutPlantillas';
import {
  changeCalidadSinExtra,
  checkApartado,
  getLocalStorage,
  paintUrl,
  reemplazarURLPrincipal,
  validateUrl
} from '../../utils/functions';
import Loading from '../Loading/Loading';
import AlertInputUrl from '../AlertInputImg/AlertInputUrl';
import { useApartados } from '../FicharPost/store/apartados';
import { toast } from 'react-toastify';
import moment from 'moment';
import useFillTabs from '../FillTabs/useFillTabs';
import { useValuesPortada } from './store/store';

const opcionsExtencionesIdiomas = [
  { label: 'Latino', value: 'Latino' },
  { label: 'Inglés', value: 'Inglés' },
  { label: 'Latino-Ingles', value: 'Latino-Ingles' },
  { label: 'Castellano', value: 'Castellano' },
  { label: 'Subtitulado', value: 'Subtitulado' },
  { label: 'Japonés', value: 'Japonés' }
];

const LayoutCrearPortada = ({ isPoster, content, setPostData, setContent }) => {
  const [metadata, setMetadata] = useState(Mymetadata);
  const [isLoading, setisLoading] = useState(false);
  // const [isLoadingLayout, setisLoadingLayout] = useState(false);
  const {
    dataAEnviar,
    movie,
    search,
    stateCalidadesSelected,
    postWordpress,
    loading: loadingStore,
    view
  } = useStore();

  const [activarPoster, setActivarPoster] = useState();
  const [cargarposter, setCargarPoster] = useState(false);
  const [showModalPlantillas, setShowModalPlantillas] = useState(false);
  const [showModalPlantillasCasas, setShowModalPlantillasCasas] =
    useState(false);
  const [showModalPlantillasCasasPlus, setShowModalPlantillasCasasPlus] =
    useState(false);
  const [showModalCapturas, setShowModalCapturas] = useState(false);
  const [showModalImages, setShowModalImages] = useState(false);
  const [showMessageAlert, setShowMessageAlert] = useState(false);

  const { poster_path, poster_path_respaldo } = movie || {};

  const dispatch = useDispatch();

  const {
    Calidad,
    Resolucion,
    Idioma,
    Formato,
    Code,
    Extra,
    urlpostergenerado,
    Plus,
    portadaSerieOAnime
  } = dataAEnviar || {};

  //----

  const [loading, setLoading] = useState({
    urlposter: false,
    posterGenerador: false,
    subir: false,
    subirImgur: false
  });
  const [loadingSubir, setLoadingSubir] = useState(false);
  const [loadingSubirImgur, setLoadingSubirImgur] = useState(false);
  const [loadingSubirPixhost, setLoadingSubirPixhost] = useState(false);
  const [urlposter, setUrlPoster] = useState(poster_path);
  const { apartados } = useApartados();
  const userData = getLocalStorage('user');
  const { handleSetKey } = useFillTabs();
  const { setPostValuesPortada } = useValuesPortada();

  const handleChangePoster = (posterUrl, name) => {
    switch (name) {
      case 'urlposter':
        dispatch({
          type: types.MOVIE,
          payload: { ...movie, poster_path: posterUrl }
        });
        dispatch({
          type: types.MOVIE,
          payload: { ...movie, poster_path_respaldo: posterUrl }
        });
        setUrlPoster(posterUrl);
        break;
      case 'urlpostergenerado':
        dispatch({
          type: types.DATAAENVIAR,
          payload: { ...dataAEnviar, urlpostergenerado: posterUrl }
        });
        dispatch({
          type: types.MOVIE,
          payload: { ...movie, poster_path: posterUrl }
        });
        break;

      default:
        break;
    }
  };

  const handlePoster = async values => {
    // if (!checkApartado(values)) {
    //     console.log('SI ESTA APARTADO!!!!!!!!!! GENERAR POST');
    //     return;
    // }
    setLoading({ posterGenerador: true });

    // validamos que tipo de url viene en los checboxes
    const urlPortadaSerieOAnime =
      portadaSerieOAnime === 'serie'
        ? Extra === 'ninguna'
          ? 'https://i.ibb.co/tQv8rjP/SERIES.png'
          : 'https://i.ibb.co/zGhdTYn/Series-con-casa.png'
        : portadaSerieOAnime === 'anime'
        ? Extra === 'ninguna'
          ? 'https://i.ibb.co/1Jpcqj6/ANIME.png'
          : 'https://i.ibb.co/L17nRxw/ANIME.png'
        : portadaSerieOAnime === 'wwe'
        ? 'https://i.ibb.co/4NS36Lk/WWE.png'
        : '';

    const { poster } = await generarPoster({
      poster: poster_path_respaldo,
      calidad: urlPortadaSerieOAnime
        ? urlPortadaSerieOAnime
        : paintUrl(Calidad, 'Calidad', metadata), // obteneos la url del json
      idioma: paintUrl(Idioma, 'Idioma', metadata),
      resolucion: paintUrl(Resolucion, 'Resolucion', metadata),
      extra: paintUrl(Extra, 'Extra', metadata) ?? 'ninguna',
      plus: paintUrl(Plus, 'Plus', metadata) ?? 'ninguna'
    });

    // setUrlPoster(poster);
    handleChangePoster(poster, 'urlpostergenerado');
    dispatch({
      type: types.MOVIE,
      payload: { ...movie, poster_path: poster }
    });
    // dispatch({
    //     type: types.MOVIE,
    //     payload: { ...movie, poster_path_respaldo: poster }
    // });
    setLoading({ posterGenerador: false });
  };

  const changeURLHtml = values => {
    if (content && values) {
      const newHtml = reemplazarURLPrincipal(content, values);
      setPostData(prev => ({ ...prev, content: { rendered: newHtml } }));
      setContent(newHtml);
    }
  };

  const handleSubmitChangePortaa = async values => {
    // Validar si el post está apartado
    setPostValuesPortada({ values, apartados, movie });
    if (!checkApartado({ values, apartados, movie })) {
      console.log('SI ESTA APARTADO!!!!!!!!!!');
      return;
    }

    dispatch({ type: types.DATAAENVIAR, payload: values });
    dispatch({ type: types.STATE_CALIDADES_SELECTED, payload: values });
    setActivarPoster(poster_path);
    setCargarPoster(!cargarposter);

    if (values.urlpostergenerado) {
      const valiUrl = validateUrl(values.urlpostergenerado);
      setShowMessageAlert(valiUrl);
      dispatch({ type: types.VIEW, payload: 1 });
      handleSetKey('crear_post');
    }
  };

  const handleChangeCalidad = values => {
    console.log('values', values);
    dispatch({ type: types.STATE_CALIDADES_SELECTED, payload: values });
    // REEMPLAZAR LA METADATA DE LOS DROPSDOWNS PARA CAMBIAR PLANTILLA ACORDE A LOS EXTRAS
    values?.Extra === 'ninguna'
      ? setMetadata(pre => changeCalidadSinExtra('Calidad', metadata))
      : setMetadata(pre => changeCalidadSinExtra('Calidad', metadata, true));
    dispatch({ type: types.VIEW, payload: 0 });
    dispatch({ type: types.VIEW, payload: 1 });
  };

  console.log('metadata', metadata);

  /**
   *
   * @returns TRAERME TODAS LAS PLANTILLAS PARA MOSTRAR EN EL MODAL
   */
  const handledeGetImagAlls = async () => {
    setisLoading(true);
    try {
      const { data } = await getPlantillas();
      const { data: data2 } = await getPlantillasPlus();

      const dataFormat = data.map(i => ({
        label: i.name,
        value: i.name,
        url: i.url,
        id: i.id
      }));
      const dataFormat2 = data2.map(i => ({
        label: i.name,
        value: i.name,
        url: i.url,
        id: i.id
      }));

      setMetadata(() => [
        ...metadata?.map(obj =>
          obj.name === 'Extra'
            ? { name: 'Extra', data: dataFormat }
            : obj.name === 'Plus'
            ? { name: 'Plus', data: dataFormat2 }
            : obj
        )
      ]);
      dispatch({
        type: types.METADATA_EXTRAS,
        payload: [
          ...metadata?.map(obj =>
            obj.name === 'Extra'
              ? { name: 'Extra', data: dataFormat }
              : obj.name === 'Plus'
              ? { name: 'Plus', data: dataFormat2 }
              : obj
          )
        ]
      });
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.error('Error al traer las plantillas', error);
    }
  };
  const handledeGetImages = async () => {
    setisLoading(true);
    try {
      const { data } = await getPlantillas();

      const dataFormat = data.map(i => ({
        label: i.name,
        value: i.name,
        url: i.url,
        id: i.id
      }));

      setMetadata(() => [
        ...metadata?.map(obj =>
          obj.name === 'Extra' ? { name: 'Extra', data: dataFormat } : obj
        )
      ]);
      dispatch({
        type: types.METADATA_EXTRAS,
        payload: [
          ...metadata?.map(obj =>
            obj.name === 'Extra' ? { name: 'Extra', data: dataFormat } : obj
          )
        ]
      });
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.error('Error al traer las plantillas', error);
    }
  };
  const handledeGetImagesPlus = async () => {
    setisLoading(true);
    try {
      const { data } = await getPlantillasPlus();
      const dataFormat = data.map(i => ({
        label: i.name,
        value: i.name,
        url: i.url,
        id: i.id
      }));
      setMetadata(() => [
        ...metadata?.map(obj =>
          obj.name === 'Plus' ? { name: 'Plus', data: dataFormat } : obj
        )
      ]);
      dispatch({
        type: types.METADATA_EXTRAS,
        payload: [
          ...metadata?.map(obj =>
            obj.name === 'Plus' ? { name: 'Plus', data: dataFormat } : obj
          )
        ]
      });
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.error('Error al traer las plantillas', error);
    }
  };

  useEffect(() => {
    handledeGetImagAlls();
  }, []);

  useEffect(() => {
    if (activarPoster) {
      handlePoster();
    }
  }, [activarPoster, cargarposter]);

  // validar la visualizacion de la alerta de subir a imgbb
  useEffect(() => {
    if (urlpostergenerado) {
      const valiUrl = validateUrl(urlpostergenerado);
      setShowMessageAlert(valiUrl);
      changeURLHtml(urlpostergenerado);
    }
  }, [urlpostergenerado]);

  if (isLoading || loadingStore) {
    return <Loading />;
  }

  return (
    <div className={styles.container__step1}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          Calidad: stateCalidadesSelected?.Calidad ?? '60FPS',
          Resolucion: stateCalidadesSelected?.Resolucion ?? 'i480p',
          Idioma: stateCalidadesSelected?.Idioma ?? 'Latino',
          Formato: stateCalidadesSelected?.Formato ?? 'MKV',
          Code: stateCalidadesSelected?.Code ?? 'H264',
          Extra: stateCalidadesSelected?.Extra ?? 'ninguna',
          Plus: stateCalidadesSelected?.Plus ?? 'ninguna',
          portadaSerieOAnime: search?.typeSearch === '0' ? 'serie' : '',
          urlposter: poster_path_respaldo,
          urlpostergenerado: urlpostergenerado,
          extension: stateCalidadesSelected?.extension ?? '',
          extensionIdioma: stateCalidadesSelected?.extensionIdioma ?? '',
          movieDetails: movie
        }}
        validationSchema={validationSchemaToPoster}
        onSubmit={values => handleSubmitChangePortaa(values)}>
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          submitForm
        }) => (
          <MyForm id="CreateForm111">
            {/* <code>{JSON.stringify(stateCalidadesSelected)}</code> */}
            {/* <div
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        /> */}
            <Row className="mb-2">
              <Col xs={12}>
                <Form.Check
                  id="pserie"
                  inline
                  label="Serie"
                  name="portadaSerieOAnime"
                  type="radio"
                  value="serie"
                  checked={values.portadaSerieOAnime === 'serie'}
                  onChange={handleChange}
                />
                <Form.Check
                  id="panime"
                  inline
                  label="Anime"
                  name="portadaSerieOAnime"
                  type="radio"
                  value="anime"
                  onChange={handleChange}
                  checked={values.portadaSerieOAnime === 'anime'}
                />
                <Form.Check
                  id="pwwe"
                  inline
                  label="WWE"
                  name="portadaSerieOAnime"
                  type="radio"
                  value="wwe"
                  onChange={handleChange}
                  checked={values.portadaSerieOAnime === 'wwe'}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {metadata?.map(items => (
                  <>
                    {touched[items.name] && errors[items.name] ? (
                      <div key={items.name} className="error-message">
                        {errors[items.name]}
                      </div>
                    ) : null}
                    <InputGroup size="sm" className="mb-3" key={items.name}>
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        {items.name === 'Extra'
                          ? 'Casas'
                          : items.name === 'Plus'
                          ? 'Extras'
                          : items.name}
                      </InputGroup.Text>
                      <Dropdown>
                        <Form.Select
                          defaultValue={values[items.name]}
                          aria-label="Default select example"
                          size="sm"
                          name={items.name}
                          onChange={handleChange}
                          onBlur={e => {
                            handleBlur(e);
                            handleChangeCalidad(values);
                          }}>
                          {items.name === 'Extra' ? (
                            <option value={'ninguna'} key={'ninh'}>
                              {'Ninguna'}
                            </option>
                          ) : null}
                          {items.name === 'Plus' ? (
                            <option value={'ninguna'} key={'ning2'}>
                              {'Ninguna'}
                            </option>
                          ) : null}

                          {items.data.map((cal, i) => (
                            <option value={cal.value} key={i}>
                              {cal.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Dropdown>
                    </InputGroup>
                  </>
                ))}
              </Col>
              <Col>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    Extension
                  </InputGroup.Text>
                  <Dropdown>
                    <Form.Select
                      // defaultValue="ligera"
                      aria-label="Default select example"
                      size="sm"
                      name="extension"
                      onChange={handleChange}
                      onBlur={e => {
                        handleBlur(e);
                        handleChangeCalidad(values);
                      }}>
                      <option value="">----</option>
                      <option value="Ligera">Ligera</option>
                      <option value="Pesada">Pesada</option>
                    </Form.Select>
                  </Dropdown>
                </InputGroup>
                {/* <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-sm">
                                        Ext. Idioma
                                    </InputGroup.Text>
                                    <Dropdown>
                                        <Form.Select
                                            // defaultValue="ligera"
                                            aria-label="Default select example"
                                            size="sm"
                                            name="extensionIdioma"
                                            onChange={handleChange}
                                            onBlur={e => {
                                                handleBlur(e);
                                                handleChangeCalidad(values);
                                            }}>
                                            <option value="">----</option>
                                            {opcionsExtencionesIdiomas.map(
                                                (cal, i) => (
                                                    <option
                                                        value={cal.value}
                                                        key={i}>
                                                        {cal.label}
                                                    </option>
                                                )
                                            )}
                                        </Form.Select>
                                    </Dropdown>
                                </InputGroup> */}
              </Col>

              <Col className="d-flex flex-column align-items-center">
                <div className="m-3">
                  <Button
                    onClick={() => setShowModalImages(!showModalImages)}
                    size="sm"
                    props={{
                      size: 'sm',
                      variant: 'flat',
                      type: 'button'
                    }}>
                    <div className="d-flex gap-2 align-items-center">
                      <BsImages />
                      Imagenes
                    </div>
                  </Button>
                </div>
                <div className="m-3">
                  <Button
                    onClick={() => setShowModalPlantillas(!showModalPlantillas)}
                    size="sm"
                    props={{
                      size: 'sm',
                      variant: 'flat',
                      type: 'button'
                    }}>
                    <div className="d-flex gap-2 align-items-center">
                      <FaSearch />
                      Plantillas
                    </div>
                  </Button>
                </div>
                <div className="m-3">
                  <Button
                    onClick={() => setShowModalCapturas(!showModalCapturas)}
                    size="sm"
                    props={{
                      size: 'sm',
                      variant: 'flat',
                      type: 'button'
                    }}>
                    <div className="d-flex gap-2 align-items-center">
                      <SiCoveralls />
                      Capturas
                    </div>
                  </Button>
                </div>
                <div className="m-3">
                  <Button
                    onClick={() =>
                      setShowModalPlantillasCasas(!showModalPlantillasCasas)
                    }
                    size="sm"
                    props={{
                      size: 'sm',
                      variant: 'flat',
                      type: 'button'
                    }}>
                    <div className="d-flex gap-2 align-items-center">
                      <TbBrandNetflix />
                      Productoras
                    </div>
                  </Button>
                </div>
                <div className="m-3">
                  <Button
                    onClick={() =>
                      setShowModalPlantillasCasasPlus(
                        !showModalPlantillasCasasPlus
                      )
                    }
                    size="sm"
                    props={{
                      size: 'sm',
                      variant: 'flat',
                      type: 'button'
                    }}>
                    <div className="d-flex gap-2 align-items-center">
                      <TbBrandNetflix />
                      Extras
                    </div>
                  </Button>
                </div>
              </Col>

              {/* <button type="submit"> enviar</button> */}
            </Row>

            <Row>
              <Col md={2}>
                <p>URL Poster (original)</p>
              </Col>
              <Col lg={8} md={8}>
                <Form.Group className="mb-3" controlId="imgOriginal">
                  <Form.Control
                    type="text"
                    placeholder={
                      touched.urlposter && errors.urlposter
                        ? 'El campo es obligatorio'
                        : 'URL Poster'
                    }
                    size="sm"
                    name="urlposter"
                    onChange={handleChange}
                    onBlur={() =>
                      values.urlposter &&
                      handleChangePoster(values.urlposter, 'urlposter')
                    }
                    value={values.urlposter}
                    className={touched.urlposter && errors.urlposter && 'error'}
                  />
                </Form.Group>
              </Col>
              <Col lg={2} md={2}>
                <FileUploader
                  name="urlposter"
                  subirPoster={subirPoster}
                  setLoading={setLoading}
                  handleChangePoster={handleChangePoster}>
                  <div className="d-flex gap-2 align-items-center">
                    {loading.urlposter ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <FaUpload />
                    )}
                    Subir
                  </div>
                </FileUploader>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <p>URL Poster (Generado)</p>
              </Col>
              <Col lg={8} md={6}>
                {showMessageAlert && <AlertInputUrl />}
                <Form.Group className="mb-3" controlId="imgPoster">
                  <Form.Control
                    type="text"
                    placeholder={
                      touched.urlpostergenerado && errors.urlpostergenerado
                        ? 'El campo es obligatorio'
                        : 'Generar imagen'
                    }
                    size="sm"
                    name="urlpostergenerado"
                    onChange={handleChange}
                    onBlur={() =>
                      values.urlpostergenerado &&
                      handleChangePoster(
                        values.urlpostergenerado,
                        'urlpostergenerado'
                      )
                    }
                    className={
                      touched.urlpostergenerado &&
                      errors.urlpostergenerado &&
                      'error'
                    }
                    value={values.urlpostergenerado}
                  />
                </Form.Group>
                <Col className="d-flex justify-content-between gap-2">
                  <FileUploaderUrl
                    url={values.urlpostergenerado}
                    name="urlpostergenerado"
                    subirPoster={subirPoster}
                    handleChangePoster={handleChangePoster}
                    setLoading={setLoadingSubir}>
                    <div className="d-flex gap-2 align-items-center">
                      {loadingSubir ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        // <FaUpload />
                        <div>
                          <img
                            src="http://www.google.com/s2/favicons?domain=www.imgbb.com"
                            alt="subir imagen"
                            title="Imgbb"
                          />
                        </div>
                      )}
                      Subir imgbb
                    </div>
                  </FileUploaderUrl>

                  <FileUploaderUrl
                    url={values.urlpostergenerado}
                    name="urlpostergenerado"
                    subirPoster={subirPoster}
                    handleChangePoster={handleChangePoster}
                    setLoading={setLoadingSubirImgur}
                    imgur>
                    <div className="d-flex gap-2 align-items-center">
                      {loadingSubirImgur ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        // <FaUpload />

                        <div>
                          <img
                            src="http://www.google.com/s2/favicons?domain=www.imgur.com"
                            alt="Subir imagen"
                            title="Imgur"
                          />
                        </div>
                      )}
                      Subir imgur
                    </div>
                  </FileUploaderUrl>
                  <FileUploaderUrl
                    url={values.urlpostergenerado}
                    name="urlpostergenerado"
                    subirPoster={subirPoster}
                    handleChangePoster={handleChangePoster}
                    setLoading={setLoadingSubirPixhost}
                    pixhost>
                    <div className="d-flex gap-2 align-items-center">
                      {loadingSubirPixhost ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        // <FaUpload />

                        <div>
                          <img
                            src="http://www.google.com/s2/favicons?domain=www.pixhost.to"
                            alt="subir imagen"
                            title="Pixhost"
                          />
                        </div>
                      )}
                      Subir pixhost
                    </div>
                  </FileUploaderUrl>
                </Col>
              </Col>
              <Col lg={2} md={4}>
                <div className="d-flex gap-2">
                  <Button
                    props={{
                      size: 'sm',
                      variant: 'flat',
                      type: 'button',
                      disabled: loading.posterGenerador && true
                    }}
                    // onClick={handlePoster}
                    // onClick={() => {
                    //     setActivarPoster(poster_path);
                    //     setCargarPoster(!cargarposter);
                    // }}
                    onClick={() => {
                      handleSubmitChangePortaa(values)
                        .then(() => {
                          submitForm();
                        })
                        .catch(() => {
                          console.error('Error al enviar el formulario');
                        });
                    }}>
                    <div className="d-flex gap-2 align-items-center">
                      {loading.posterGenerador ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <FaRegImage />
                      )}
                      Generar
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
          </MyForm>
        )}
      </Formik>

      <Modal
        size="lg"
        show={
          showModalPlantillas ||
          showModalImages ||
          showModalCapturas ||
          showModalPlantillasCasas ||
          showModalPlantillasCasasPlus
        }
        onHide={() => {
          setShowModalPlantillas(false);
          setShowModalImages(false);
          setShowModalCapturas(false);
          setShowModalPlantillasCasas(false);
          setShowModalPlantillasCasasPlus(false);
        }}
        fullscreen={'lg-down'}
        // size="lg"
        // dialogClassName="modal-90w"
        // aria-labelledby="contained-modal-title-vcenter"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {showModalPlantillas
              ? 'Plantillas'
              : showModalPlantillasCasas
              ? 'Casas Productoras'
              : 'Resultado'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showModalImages && <GroupImages movie={movie} />}
          {showModalPlantillas && (
            <Groupplantillas
              movie={movie}
              handleChangePoster={handleChangePoster}
            />
          )}
          {showModalCapturas && <ShowCapturas />}
          {showModalPlantillasCasas && (
            <LayoutPlantillas
              metadata={metadata}
              setMetadata={setMetadata}
              handledeGetImages={handledeGetImages}
              isLoading={isLoading}
              crearPlantillaPoster={crearPlantillaPoster}
              deletePlantilla={deletePlantilla}
              subirPlantilla={subirPlantilla}
            />
          )}
          {showModalPlantillasCasasPlus && (
            <LayoutPlantillas
              metadata={metadata}
              setMetadata={setMetadata}
              handledeGetImages={handledeGetImagesPlus}
              isLoading={isLoading}
              deletePlantilla={deletePlantillaPlus}
              subirPlantilla={subirPlantillaPlus}
              plus
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LayoutCrearPortada;
