import React from 'react';
import { Box, Checkbox, Stack } from '@mui/material';
import { Form, InputGroup } from 'react-bootstrap';
import useSeason from './useSeason';
import DragAndDrop from '../DragArea/DragAndDrop';

const LayoutSeasons = () => {
  const {
    handleChange,
    handleChangePoster,
    handleCheckboxChange,
    handleChangeSize,
    handleChangeDataAEnviar,
    seasons,
    movie,
    sizeAll,
    totalSize,
    temporada,
    capitulos,
    setTemporada
  } = useSeason();

  return (
    <Stack className="color_fondo" m={1} justifyContent={'center'}>
      {seasons?.map((serie, i) => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'start'}>
          <Stack alignItems={'flex-start'}>
            <Checkbox
              // defaultChecked={serie.checked}
              checked={serie?.checked}
              onChange={() => handleCheckboxChange(i, serie)}
            />
          </Stack>
          <Box width={'100%'}>
            <InputGroup size="sm" key={i}>
              <InputGroup.Text
                id="basic-addon1"
                style={
                  movie.number_of_seasons === serie?.season_number &&
                  serie?.episode_count >
                    movie?.last_episode_to_air?.episode_number
                    ? { backgroundColor: '#ff8e8e' }
                    : null
                }>
                {serie?.name}
              </InputGroup.Text>
              <Form.Control
                style={
                  movie.number_of_seasons === serie?.season_number &&
                  serie?.episode_count >
                    movie?.last_episode_to_air?.episode_number
                    ? { backgroundColor: '#ff8e8e' }
                    : null
                }
                placeholder="Numero de episodios"
                aria-label="Numero de episodios"
                aria-describedby="basic-addon1"
                // value={`${peli.title} ${peli?.release_date.slice(
                //     0,
                //     4
                // )}`}
                value={serie?.title_episodios}
                onChange={e => handleChange(i, e.target.value)}
              />
            </InputGroup>
          </Box>
          <Box sx={StyleForDragAndDrop}>
            <DragAndDrop
              handleChange={e => handleChangeSize(i, e)}
              title="Carpeta"
              isFolder
            />
          </Box>
          <Box ml={2}>
            <Box
              mb={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleChangePoster(serie?.poster_path)}>
              <img
                src={`https://www.themoviedb.org/t/p/w600_and_h900_bestv2${serie?.poster_path}`}
                alt=""
                style={{ width: '300px' }}
              />
            </Box>
          </Box>
        </Stack>
      ))}
      {/* <DragAndDrop
                handleChange={handleChangeSize}
                title="Arrastra y suelta una carpeta"
                isFolder
            /> */}

      <Stack spacing={2} direction="row">
        {/* Primera Columna */}
        <Stack spacing={2} direction="column" flex={1}>
          <Box width={'100%'} mt={2}>
            <InputGroup size="sm">
              <InputGroup.Text id="basic-addon1">Peso</InputGroup.Text>
              <Form.Control
                placeholder="Peso"
                aria-label="Peso"
                aria-describedby="basic-addon2"
                // value={`${peli.title} ${peli?.release_date.slice(
                //     0,
                //     4
                // )}`}
                value={totalSize}
                onChange={e =>
                  handleChangeDataAEnviar(e.target.value, 'PesoArchivo')
                }
              />
            </InputGroup>
          </Box>

          <Box width={'100%'} mt={2}>
            <InputGroup size="sm">
              <InputGroup.Text id="basic-addon1">Capitulos</InputGroup.Text>
              <Form.Control
                placeholder="Capitulos"
                aria-label="Capitulos"
                aria-describedby="basic-addon2"
                value={capitulos}
                onChange={e =>
                  handleChangeDataAEnviar(e.target.value, 'capitulo')
                }
              />
            </InputGroup>
          </Box>
          <Box width={'100%'} mt={2}>
            <Form.Check
              id="tipoCapitulosTemporada"
              type="checkbox"
              label="x Temporada"
              onChange={e => {
                const isChecked = e.target.checked;
                const baseCapitulos = capitulos.split(' ')[0]; // Obtiene solo el rango de capítulos
                const newValue = isChecked
                  ? `${baseCapitulos} x Temporada`
                  : baseCapitulos;
                handleChangeDataAEnviar(newValue, 'capitulo');
              }}
            />
          </Box>
        </Stack>
        {/* Segunda Columna */}
        <Stack spacing={2} direction="column" flex={1}>
          <Stack spacing={2} direction="row" flex={1}>
            <Box width={'100%'} mt={2}>
              <InputGroup size="sm">
                <InputGroup.Text id="basic-addon1">Desde</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Desde"
                  aria-label="desde"
                  aria-describedby="basic-addon2"
                  style={{
                    width: '70px',
                    textAlign: 'center',
                    WebkitAppearance: 'none',
                    MozAppearance: 'textfield'
                  }}
                  min={1}
                  onChange={e => {
                    const desde = e.target.value;
                    const hasta = e.target.value;
                    handleChangeDataAEnviar(`${desde}`, 'temporada');
                    setTemporada(`${desde}`);
                  }}
                />
              </InputGroup>
            </Box>
            <Box width={'100%'} mt={2}>
              <InputGroup size="sm">
                <InputGroup.Text id="basic-addon1">Hasta</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Hasta"
                  aria-label="hasta"
                  aria-describedby="basic-addon2"
                  style={{
                    width: '70px',
                    textAlign: 'center',
                    WebkitAppearance: 'none',
                    MozAppearance: 'textfield'
                  }}
                  min={0}
                  onChange={e => {
                    const hasta = e.target.value;
                    const newTemp =
                      hasta === '0'
                        ? `${temporada.split(' ')[0]}`
                        : `${temporada.split(' ')[0]} a la ${hasta}`;
                    handleChangeDataAEnviar(newTemp, 'temporada');
                    setTemporada(newTemp);
                  }}
                />
              </InputGroup>
            </Box>
          </Stack>
          <Box width={'100%'} mt={2}>
            <InputGroup size="sm">
              <InputGroup.Text id="basic-addon1">Temporada</InputGroup.Text>
              <Form.Control
                placeholder="Temporada"
                aria-label="Temporada"
                aria-describedby="basic-addon2"
                value={temporada}
                onChange={e =>
                  handleChangeDataAEnviar(e.target.value, 'temporada')
                }
              />
            </InputGroup>
          </Box>
          <Stack direction="row" spacing={2} mt={2}>
            <Form.Check
              id="tipoTemporadaEmisión"
              type="radio"
              label="Emisión"
              name="tipoTemporada"
              onChange={() => {
                const tempParts = temporada.split(' ');
                const baseTemp =
                  tempParts.length > 3
                    ? `${tempParts[0]} a la ${tempParts[3]}`
                    : tempParts[0];
                setTemporada(`${baseTemp} Emisión`);
                handleChangeDataAEnviar(`${baseTemp} Emisión`, 'temporada');
              }}
            />
            <Form.Check
              id="tipoTemporadaCompleta"
              type="radio"
              label="Completa"
              name="tipoTemporada"
              onChange={() => {
                const tempParts = temporada.split(' ');
                const baseTemp =
                  tempParts.length > 3
                    ? `${tempParts[0]} a la ${tempParts[3]}`
                    : tempParts[0];
                setTemporada(`${baseTemp} Completa`);
                handleChangeDataAEnviar(`${baseTemp} Completa`, 'temporada');
              }}
            />
            <Form.Check
              id="tipoTemporadaCompletas"
              type="radio"
              label="Completas"
              name="tipoTemporada"
              onChange={() => {
                const tempParts = temporada.split(' ');
                const baseTemp =
                  tempParts.length > 3
                    ? `${tempParts[0]} a la ${tempParts[3]}`
                    : tempParts[0];
                setTemporada(`${baseTemp} Completas`);
                handleChangeDataAEnviar(`${baseTemp} Completas`, 'temporada');
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LayoutSeasons;

const StyleForDragAndDrop = {
  width: '50%',
  height: '10%'
};
