import React from 'react';
import './MovieCard.css';
import { MdClose } from 'react-icons/md';

const decodeHtmlEntities = text => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};

const MovieCard = ({ title, url, status, user, onClose }) => {
  const decodedTitle = decodeHtmlEntities(title);

  return (
    <div className="movie-card">
      <div className="movie-card__content">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="movie-card__title">
          {decodedTitle}
        </a>
      </div>
      <div className="movie-card__content">
        <div>
          <span className={`status-badge ${status}`}>{status}</span>
        </div>
        {user && (
          <div>
            <span className="movie-card__user">{user}</span>
          </div>
        )}
        <button className="movie-card__close" onClick={onClose} title="Cerrar">
          <MdClose size={18} />
        </button>
      </div>
    </div>
  );
};

export default MovieCard;
